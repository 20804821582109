import FeaturesList from "components/Layouts/FeaturesList/FeaturesList";
import { VideoSection } from "components/Layouts/Integrations";
import LiveDemoForm from "components/Layouts/LiveDemoForm/LiveDemoForm";
import Tabs from "components/Layouts/Tabs/Tabs";
import ReviewsContent from "components/Layouts/Reviews/ReviewsContent";
import { StarRating } from "Pages/Reviews/Reviews";
import { Helmet } from "react-helmet";
import { inventoryManagementContent } from "data/featuresData";
import ProLogoCarousel from "components/Layouts/LogoCarousel/ProLogoCarousel";

const images = {
  starIcon: "icons/star.svg",
  shopifyLogo: "logos/shopify.svg",
  capterraLogo: "logos/capterra.svg",
  previewProVideo: "images/skulapro-video-preview.webp",
  previewInventoryVideo: "images/inventory-preview.webp",
  heroImage: "images/hero-homepage.svg",
};

const videoSrcPro =
  "https://fast.wistia.net/embed/iframe/q2c07tksbt?seo=false&videoFoam=true";
const videoSrcInventory = "https://youtu.be/jgB5SjG8zwE?t=94";

const Homepage = () => {
  const tabs = [
    {
      id: "pro",
      title: "SKULabs PRO",
      description: "Ideal for companies needing an all-in-one solution",
      content: (
        <>
          <VideoSection
            video={videoSrcPro}
            previewVideoDesc={images.previewProVideo}
          />
          <FeaturesList />
          <ProLogoCarousel />
        </>
      ),
    },
    {
      id: "inventory",
      title: "SKULabs Inventory",
      description:
        "Ideal for small teams needing simple inventory control and tracking",
      content: (
        <>
          <VideoSection
            video={videoSrcInventory}
            previewVideoDesc={images.previewInventoryVideo}
          />
          <FeaturesList featureList={inventoryManagementContent} />
          <div className="demo-container">
            <h3>
              Our experts are ready to help you. <br /> We are here to solve
              your business challenges.
            </h3>
            <h3>Start your free demo today!</h3>
            <LiveDemoForm />
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          SKULabs – Comprehensive Inventory and Shipping Management for
          eCommerce Success
        </title>
        <meta
          name="description"
          content="Optimize your eCommerce operations with SKULabs' all-in-one solution for inventory control and shipping management. Enhance accuracy, streamline processes, and boost customer satisfaction with our user-friendly platform."
        />
      </Helmet>
      <main className="homepage">
        <div className="hero container">
          <div className="hero-content">
            <div className="hero-content-container">
              <h1 className="hero-title">
                Experience Seamless{" "}
                <span className="accent">Inventory Control</span> with{" "}
                <span className="accent">Powerful Shipping</span> Solutions
              </h1>
              <p className="description">
                Simplify your business operations with an app that handles
                inventory and shipping in one place. Stay organized, boost
                efficiency, and manage everything easily—no technical skills
                required. Fast results, easy setup.
              </p>
              <LiveDemoForm />
              <div className="testimonial-companies">
                <a
                  href="https://apps.shopify.com/skulabs/reviews"
                  className="testimonial-company"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={images.shopifyLogo}
                    alt="Shopify Logo"
                    width={108}
                    height={32}
                  />
                  <div className="stars-container">
                    4.9
                    <StarRating stars={5} />
                  </div>
                </a>
                <a
                  href="https://www.capterra.com/p/148441/SKULabs/reviews/"
                  className="testimonial-company"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={images.capterraLogo}
                    alt="Capterra Logo"
                    width={140}
                    height={32}
                  />
                  <div className="stars-container">
                    4.6
                    <StarRating stars={5} />
                  </div>
                </a>
              </div>
            </div>
          </div>

          <img src={images.heroImage} alt="SKULabs" width={572} height={655} />
        </div>
        <div className="apps">
          <h2>Explore what we can do for you</h2>
          <p>
            Select the SKULabs solution that suits your needs, whether you need
            all-in-one power or simple inventory control.
          </p>
          <Tabs tabs={tabs} defaultActiveTab="pro" />
        </div>
      </main>
      <div className="container">
        <h2 style={{ textAlign: "center" }}>
          What Our Clients Think about <span className="accent">SKULabs</span>{" "}
        </h2>
      </div>
      <ReviewsContent />
    </>
  );
};

export default Homepage;
