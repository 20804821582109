import React from "react";

interface FAQContentText {
  type: "text";
  content: string;
}

interface FAQContentImage {
  type: "image";
  content: string;
  alt?: string;
}

type FAQContent = FAQContentText | FAQContentImage;

export interface FAQItemProps {
  question: string;
  answer: FAQContent[];
  category?: string;
}

interface FAQItemComponentProps {
  item: FAQItemProps;
  isOpen: boolean;
  onClick: () => void;
}

const FAQItemComponent: React.FC<FAQItemComponentProps> = ({
  item,
  isOpen,
  onClick,
}) => {
  return (
    <div className={`faq-item ${isOpen ? "open" : ""}`}>
      <p
        className="title"
        onClick={onClick}
        aria-expanded={isOpen}
        role="button"
      >
        {item.question}
      </p>
      <div className="faq-answer">
        {item.answer.map((element, i) => {
          switch (element.type) {
            case "text":
              return <p key={i}>{element.content}</p>;
            case "image":
              return (
                <img
                  key={i}
                  src={element.content}
                  alt={element.alt || "FAQ image"}
                />
              );
            default:
              return null;
          }
        })}
      </div>
    </div>
  );
};

export default React.memo(FAQItemComponent);
