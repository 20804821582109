// utils/utm.js
import queryString from 'query-string';

export const getAndStoreUtmParams = () => {
  try {
    const existingUtmData = JSON.parse(sessionStorage.getItem('utm_data') || 'null') || {};
    const params = queryString.parse(window.location.search);
    const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
    const newUtmData = { ...existingUtmData };

    utmParams.forEach(param => {
      if (params[param] && !existingUtmData[param]) {
        newUtmData[param] = params[param];
      }
    });

    if (Object.keys(newUtmData).length > 0 && !sessionStorage.getItem('utm_data')) {
      sessionStorage.setItem('utm_data', JSON.stringify(newUtmData));
    }
  } catch (e) {
    console.error('Failed to store UTM data', e);
  }
};

export const getUtmData = () => {
  const utmData = sessionStorage.getItem('utm_data');
  return utmData ? JSON.parse(utmData) : null;
};
