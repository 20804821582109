import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logos from "data/integrationLogos";

interface Logo {
  src: string;
  companyName: string;
}

interface LogoCarouselProps {
  direction: "left" | "right";
  keysToShow: string[];
}

const LogoCarousel: React.FC<LogoCarouselProps> = ({
  direction,
  keysToShow,
}) => {
  const [filteredLogos, setFilteredLogos] = useState<Logo[]>([]);
  const directionClass = direction === "left" ? "scroll-left" : "scroll-right";

  useEffect(() => {
    const loadLogos = async () => {
      const loadedLogos = await Promise.all(
        keysToShow.map(async (key) => {
          if (logos[key as keyof typeof logos]) {
            const logoSrc = await logos[key as keyof typeof logos].primary();
            return { src: logoSrc, companyName: key };
          }
          return null;
        })
      );
      setFilteredLogos(loadedLogos.filter((logo) => logo !== null) as Logo[]);
    };

    loadLogos();
  }, [keysToShow]);

  return (
    <div className="carousel">
      <div className={`carousel-track ${directionClass}`}>
        {filteredLogos.concat(filteredLogos).map((logo, index) => {
          const link = `/integrations/${logo.companyName
            .toLowerCase()
            .replace(/\s/g, "")}`;

          return (
            <Link
              to={link}
              className="carousel-item"
              key={`${logo.companyName}-${index}`}
            >
              <img
                src={logo.src}
                alt={`Logo ${logo.companyName}`}
                loading="lazy"
                height={36}
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default LogoCarousel;
