// FAQ.tsx
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import FAQItemComponent from "./FAQItem";
import { FAQItemProps } from "data/faqData";

interface FAQProps {
  items?: FAQItemProps[];
}

const FAQ: React.FC<FAQProps> = ({ items = [] }) => {
  const [openIndex, setOpenIndex] = useState<number>(0);

  const handleToggle = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: items.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: item.answer
          .filter((a) => a.type === "text")
          .map((a) => (a as any).content)
          .join(" "),
      },
    })),
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
      </Helmet>
      <div className="faq container">
        <h2>Most popular questions from customers</h2>
        <div className="faq-items">
          {items.map((item, index) => (
            <FAQItemComponent
              key={index}
              item={item}
              isOpen={openIndex === index}
              onClick={() => handleToggle(index)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default FAQ;
