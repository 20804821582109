import { ChangeEventHandler, FocusEventHandler, forwardRef } from "react";

interface IInputProps {
  label?: string;
  name: string;
  type?: string;
  value?: string;
  placeholder?: string;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  error?: string;
  disabled?: boolean;
}

const Input = forwardRef<HTMLInputElement, IInputProps>(
  ({ label, type = "text", placeholder, error, disabled, ...rest }, ref) => {
    return (
      <div className="input">
        {label && <label>{label}</label>}
        <input
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          ref={ref}
          {...rest}
        />
        {error && <p className="error-message">{error}</p>}
      </div>
    );
  }
);

export default Input;
