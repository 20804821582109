import { useMemo } from "react";
import ReviewsSlider from "./ReviewsSlider";
import reviewsData from "data/reviewsData";

const ReviewsContent = () => {
  const images = useMemo(
    () => ({
      starIcon: "icons/star.svg",
      linkArrow: "icons/links-arrow.svg",
      shopifyLogo: "logos/shopify.svg",
      capterraLogo: "logos/capterra.svg",
      getAppLogo: "logos/getapp.svg",
      navigationArrow: "icons/slider-arrow.svg",
      defaultAvatar: "icons/default-avatar.svg",
    }),
    []
  );

  return (
    <>
      <div className="container">
        <div className="testimonial">
          <div className="testimonial-companies">
            {[
              {
                href: "https://apps.shopify.com/skulabs/reviews",
                logo: images.shopifyLogo,
                annotation: '"Exceptional Customer Experience"',
              },
              {
                href: "https://www.capterra.com/p/148441/SKULabs/reviews/",
                logo: images.capterraLogo,
                annotation: '"Highly Rated by Experts"',
              },
              {
                href: "https://www.getapp.com/industries-software/a/skulabs/reviews/",
                logo: images.getAppLogo,
                annotation: '"Trusted by Professionals"',
              },
            ].map((company, index) => (
              <a
                key={index}
                href={company.href}
                className="testimonial-company"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={company.logo} alt="Company Logo" />
                <p className="testimonial-annotation">{company.annotation}</p>
                <div className="testimonial-rating">
                  {[...Array(5)].map((_, index) => (
                    <img
                      key={index}
                      src={images.starIcon}
                      alt="Filled Star"
                      width="24px"
                      height="24px"
                    />
                  ))}
                </div>
              </a>
            ))}
          </div>

          <ReviewsSlider reviewsData={reviewsData} />
        </div>
      </div>
    </>
  );
};

export default ReviewsContent;
