interface ICalendlyWrapperProps {
  src: string;
}

export const CalendlyWrapper = (props: ICalendlyWrapperProps) => {
  return (
    <>
      <script
        type="text/javascript"
        src="https://assets.calendly.com/assets/external/widget.js"
        async
      ></script>
      <iframe
        title="calendar"
        src={props.src}
        width="100%"
        height="700"
        frameBorder="0"
        className="calendar"
      />
    </>
  );
};
