export const convertToEasternTime = (date: Date) => {
  const EST = -5;
  const isDST = (date: Date) => {
    const start = new Date(date.getFullYear(), 2, 14);
    start.setDate(14 - start.getDay());
    const end = new Date(date.getFullYear(), 10, 7);
    end.setDate(7 - end.getDay());
    return date >= start && date < end;
  };
  const offset = isDST(date) ? EST + 1 : EST;
  return new Date(
    date.getTime() + (offset * 60 + date.getTimezoneOffset()) * 60000
  );
};

export const isHoliday = (date: Date) => {
  const month = date.getMonth();
  const day = date.getDate();
  if (
    (month === 11 && day === 24) || // Christmas Eve
    (month === 11 && day === 25) || // Christmas
    (month === 6 && day === 4) || // 4th of July
    (month === 0 && day === 1) || // New Year's Day
    (month === 11 && day === 31) // New Year's Eve
  ) {
    return true;
  }
  if (
    month === 10 &&
    (day - date.getDay() + 1) / 7 === 4 &&
    date.getDay() === 4
  ) {
    return true;
  }
  return false;
};

export const isChatOnline = () => {
  const now = new Date();
  const etNow = convertToEasternTime(now);
  if (etNow.getDay() === 0 || etNow.getDay() === 6) {
    return false;
  }
  if (isHoliday(etNow)) {
    return false;
  }
  const hour = etNow.getHours();
  return hour >= 9 && hour < 19;
};

export default isChatOnline;
