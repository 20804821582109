import { Link } from "react-router-dom";

//logos
import amazon from "assets/brand_logos/integrations/amazon.svg";
import shopify from "assets/brand_logos/integrations/shopify.svg";
import ebay from "assets/brand_logos/integrations/ebay.svg";
import walmart from "assets/brand_logos/integrations/walmart.svg";
import qb from "assets/brand_logos/integrations/quickbooks.svg";
import usps from "assets/brand_logos/integrations/usps.svg";
import fedex from "assets/brand_logos/integrations/fedex.svg";
import ups from "assets/brand_logos/integrations/ups.svg";

const TargetIntegration: React.FC = () => {
  const integrationImg = "/images/integration.gif";
  return (
    <section className="target-integration">
      <div className="container">
        <div className="integration-gif">
          <img
            src={integrationImg}
            alt="Finger target"
            loading="lazy"
            width={604}
            height={646}
          />
        </div>
        <div className="target-integration-info">
          <h2>
            Integration with <span className="accent">Over 50 Companies</span>
          </h2>
          <p>
            Our application provides robust directory management, featuring
            integration with over 50 companies including e-commerce platforms
            and delivery services worldwide. Key features include Inventory
            Management, Barcode-based Picking, Multi-carrier Shipping, and
            Purchasing & Receiving.
          </p>
          <div className="logos">
            <img
              src={amazon}
              alt="Amazon"
              className="logo"
              loading="lazy"
              width={119}
              height={36}
            />
            <img
              src={shopify}
              alt="Shopify"
              className="logo"
              loading="lazy"
              width={123}
              height={36}
            />
            <img
              src={ebay}
              alt="Ebay"
              className="logo"
              loading="lazy"
              width={91}
              height={36}
            />
            <img
              src={walmart}
              alt="Walmart"
              className="logo"
              loading="lazy"
              width={149}
              height={36}
            />
            <img
              src={qb}
              alt="Quickbooks"
              className="logo"
              loading="lazy"
              width={187}
              height={36}
            />
            <img
              src={usps}
              alt="USPS"
              className="logo"
              loading="lazy"
              width={196}
              height={36}
            />
            <img
              src={ups}
              alt="UPS"
              className="logo"
              loading="lazy"
              width={30}
              height={36}
            />
            <img
              src={fedex}
              alt="Fedex"
              className="logo"
              loading="lazy"
              width={116}
              height={36}
            />
          </div>
          <Link to={"/integrations"} className="cta">
            Other Integrations
          </Link>
        </div>
      </div>
    </section>
  );
};

export default TargetIntegration;
