import { Link } from "react-router-dom";

const SupportSection = () => {
  return (
    <section className="support">
      <div className="container">
        <div className="support-info">
          <h2>Simplify your warehouse operations with our trusted solutions</h2>
          <p className="support-description">
            Our integrations are maintained daily by a team of experts in
            ecommerce operations. Optimize your inventory, streamline warehouse
            operations, and ensure accurate fulfillment of every order. Our
            personal onboarding can be completed in days, bringing immediate
            value end-of-week, not end-of-next-quarter. As your business grows,
            our team is here to help you scale effectively. Provide your team
            with the tools they need to reduce errors, lower operational costs,
            and improve overall customer satisfaction.
          </p>
          <div className="cta-container">
            <Link to="/pricing" className="cta">
              Get started now
            </Link>
            <a href="tel:+1 (800) 243-2986" className="cta-secondary">
              Call us at: <span className="accent">+1 (800) 243-2986</span>
            </a>
          </div>
        </div>
        <div className="key-metrics">
          <div className="metric">
            <span className="number">10,000+</span>
            <span className="label">Satisfied Brands</span>
          </div>
          <div className="metric">
            <span className="number">99.9%</span>
            <span
              className="label"
              title="As reported by multiple customers, SKULabs regularly eliminates order picking mistakes from DTC and 3PL operations."
            >
              Seller Performance*
            </span>
          </div>
          <div className="metric">
            <span className="number">
              <span>up to</span> 90%
            </span>
            <span className="label">Discount on shipping</span>
          </div>
          <div className="metric">
            <span className="number">4.9 ⭐</span>
            <span className="label">Average Review</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SupportSection;
