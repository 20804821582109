import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { raitingCompanies, StarRating } from "../Reviews/Reviews";
import LiveDemoForm from "components/Layouts/LiveDemoForm/LiveDemoForm";
import SupportSection from "components/Layouts/Support/SupportSection";
import PricingTable from "Pages/Pricing/PricingTable";
import featureData from "data/featuresData";
import paths from "helpers/paths";

import FeaturesList from "components/Layouts/FeaturesList/FeaturesList";
import ProLogoCarousel from "components/Layouts/LogoCarousel/ProLogoCarousel";

const PricingNew = () => {
  const feature = featureData[paths.features].content;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const plan = searchParams.get("plan");
  const stage = searchParams.get("stage");

  const shouldShowPricingTable =
    plan === "basic2023" || plan === "pro2023" || plan === "enterprise2023";
  {
    shouldShowPricingTable && <PricingTable />;
  }
  return (
    <>
      <Helmet>
        <title>Plans & Pricing - SKULabs</title>
        <meta
          name="description"
          content="Compare SKULabs pricing plans and features to choose the best fit for your business."
        />
      </Helmet>
      <main>
        <div className="pricing-head container">
          <div className="pricing-head-info">
            <h1>Get a Quote Perfect for Your Needs</h1>
            <p>
              We offer personalized quotes for every client, recognizing that
              each business has unique needs, making a one-size-fits-all
              approach impossible.
            </p>
            <p>
              To deliver an accurate estimate, one of our experienced Account
              Executives will gather key information about your business and
              specific requirements. Afterward, we’ll provide you with a
              comprehensive pricing breakdown and analysis, highlighting areas
              where you can save and optimize your operations. Contact us today
              to get started!
            </p>
            <div className="tags">
              <div className="tag">30 day money back guarantee</div>
              <div className="tag">No contracts or onboarding fees</div>
            </div>
            <LiveDemoForm link="/call" buttonText="Get Your Quote" />
          </div>
          <div className="raiting-companies">
            {raitingCompanies.map((company) => (
              <a
                key={company.href}
                href={company.href}
                className="testimonial-company"
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`Read reviews on ${company.name}`}
              >
                <img
                  src={company.logo}
                  alt={`${company.name} logo`}
                  loading="lazy"
                  width="100px"
                  height="auto"
                />
                <p className="testimonial-annotation">{company.annotation}</p>
                <StarRating stars={5} />
              </a>
            ))}
          </div>
        </div>
      </main>

      <div className="pricing-fullinfo">
        <ProLogoCarousel />
        <FeaturesList />
      </div>
      <SupportSection />
    </>
  );
};

export default PricingNew;
