import { VideoSection } from "components/Layouts/Integrations";
import LiveDemoForm from "components/Layouts/LiveDemoForm/LiveDemoForm";
import Reviews from "Pages/Reviews/Reviews";
import { Helmet } from "react-helmet";

const HoT = () => {
  const heroImage = "/images/casestudies/hot/hero.webp";
  const challengeImage = "/images/casestudies/hot/challenge.webp";
  const resultImage = "/images/casestudies/hot/result.webp";
  const preview = "/images/casestudies/hot/videoPreview.webp";
  const videoSrc = "https://skulabs.wistia.com/medias/wixusb0s6h";
  return (
    <>
      <Helmet>
        <title>House of Tinks Case Study - SKULabs</title>
        <meta
          name="description"
          content="Learn how House of Tinks streamlined their fashion retail operations with SKULabs."
        />
      </Helmet>
      <main className="case-study">
        <div className="case-study-hero container">
          <div className="case-study-hero-info">
            <h1>House of Tinks' Success with SKULabs</h1>
            <p>
              House of Tinks, founded by Catrina, is a boutique clothing store
              dedicated to making women feel confident and look their best in
              beautifully curated fashion pieces. What began as a small,
              home-based operation with a single clothing rack grew into a
              thriving business. House of Tinks is as unique as it is passionate
              about making its customers feel loved and appreciated. That love
              comes through with each article of clothing and every accessory
              that Catrina designs and produces. However, no growing business is
              immune to growing pains, as the brand expanded, managing inventory
              and fulfilling orders became increasingly challenging, with manual
              processes leading to inefficiencies, mistakes, and lost revenue.
            </p>
          </div>
          <div className="case-study-hero-img">
            <img src={heroImage} alt="House of Tinks warehouse" />
          </div>
        </div>

        <VideoSection video={videoSrc} previewVideoDesc={preview} />

        <div className="case-study-content">
          <div className="container">
            <div className="content">
              <h2>Challenge</h2>
              <p>
                As House of Tinks expanded, they encountered several operational
                challenges that are common among fashion boutiques. Catrina and
                her team struggled with manual inventory tracking, relying on
                physical searches and spreadsheets to locate items in the
                warehouse, which resulted in time-consuming fulfillment
                processes and even shipping errors. When customers received the
                wrong items, this led to increased costs through return
                shipping, time spent with the exchange, and the cost of
                re-shipment. With hundreds of SKUs to manage, the lack of an
                effective system made scaling the business difficult, and the
                manual workflows were no longer sustainable. Like many fashion
                boutiques, they needed a reliable solution to optimize their
                warehouse operations, improve order accuracy, and save
                time—challenges that SKULabs is well-equipped to solve.
              </p>
              <h3>Improved Processes with SKULabs</h3>
              <p>
                Faced with these growing pains, House of Tinks turned to SKULabs
                to simplify and automate their operations. The initial goal was
                to have a process in place to eliminate inaccurate orders,
                reduce shipping costs, and cut time spent shipping as much as
                possible. The House of Tinks team took on this challenge and
                after implementing SKULabs, House of Tinks automated inventory
                tracking with real-time data, adopted barcode scanning to
                eliminate fulfillment errors, and optimized their warehouse
                organization.
              </p>
              <img
                src={challengeImage}
                alt="House of Tinks challenge"
                width={784}
                height={400}
                loading="lazy"
              />
              <h2>The Result</h2>
              <p>
                With SKULabs' inventory management and order fulfillment tools,
                House of Tinks was able to drastically reduce shipping times,
                prevent costly errors, and regain control over their warehouse.
                What once took hours to manage now takes half the time, allowing
                Catrina and her team to focus on what matters most—delivering
                quality products and service to their customers.
              </p>
              <h3>Key Changes in Their Process:</h3>
              <ul>
                <li>
                  <strong>Automation and accuracy:</strong> House of Tinks
                  automated manual tasks like inventory tracking with barcodes
                  and organizing their warehouse locations for order picking,
                  significantly reducing errors.
                </li>
                <li>
                  <strong>Efficiency gains:</strong> Time spent shipping was
                  reduced by 50%, meaning more orders could be processed in a
                  shorter time frame, enabling the business to scale without
                  additional labor.
                </li>
                <li>
                  <strong>Cost savings:</strong> By eliminating fulfillment
                  mistakes, House of Tinks saved money on returns and reshipping
                  costs which can be devastating to any business
                </li>
                <li>
                  <strong>Better control of inventory:</strong> SKULabs PRO gave
                  them full visibility of their inventory, making it easier to
                  forecast needs, avoid stockouts, and manage seasonal
                  collections
                </li>
              </ul>
              <img
                src={resultImage}
                alt="House of Tinks plus SKULabs PRO"
                width={784}
                height={400}
                loading="lazy"
              />
              <h2>Future</h2>
              <p>
                With SKULabs in place, the future looks bright for House of
                Tinks. Now that Catrina and her team have optimized their
                inventory and order fulfillment processes, they can focus on
                expanding their boutique and enhancing the customer experience.
                The time and cost savings gained through SKULabs allow the team
                to concentrate on curating new collections, exploring new
                fashion trends, and connecting with a broader audience both
                online and in-store.
              </p>
              <p>
                With plans to scale their online presence and potentially
                explore new markets, House of Tinks is well-positioned to handle
                increased demand without worrying about operational bottlenecks.
                The boutique can confidently expand its product offerings,
                knowing that their inventory and order fulfillment systems can
                grow with them. Additionally, the improved customer satisfaction
                from faster, error-free deliveries is helping build a loyal
                customer base that continues to drive repeat business.
              </p>
              <p>
                As House of Tinks looks toward the future, Catrina envisions
                more personalized shopping experiences, collaborations with
                other fashion brands, and possibly expanding to new physical
                locations. Armed with SKULabs’ powerful tools and the
                operational freedom they've gained, House of Tinks is ready to
                embrace the next stage of growth with confidence and creativity.
                The boutique's journey is just beginning, and the future is
                filled with exciting opportunities to make women everywhere feel
                stylish, confident, and empowered.
              </p>
              <div className="triger-section">
                <h3>Contact us today to schedule a demo </h3>
                <p>
                  Curious to see how SKULabs can help your boutique overcome
                  inventory challenges and streamline operations, just like
                  House of Tinks?
                </p>
                <p>
                  Discover the features and benefits that helped transform their
                  business and see how it can do the same for yours. From
                  inventory control to fast, accurate fulfillment, SKULabs
                  provides everything you need to grow confidently.
                </p>
                <LiveDemoForm />
              </div>
            </div>
            <aside>
              <div className="highlights">
                <h3>Pain points</h3>
                <ul>
                  <li>
                    Manual inventory tracking leading to inefficiencies and
                    fulfillment errors
                  </li>
                  <li>Time-consuming order processing, slowing down growth</li>
                  <li>
                    Frequent shipping mistakes resulting in costly returns and
                    reshipping
                  </li>
                  <li>Difficulty managing hundreds of SKUs</li>
                  <li>
                    Lack of real-time visibility into inventory, causing
                    stockouts and order delays
                  </li>
                </ul>
              </div>
              <div className="highlights">
                <h3>Solution</h3>
                <ul>
                  <li>
                    Implementation of barcode scanning to ensure order accuracy
                  </li>
                  <li>
                    Optimized warehouse organization for faster order picking
                    and reduced errors
                  </li>
                  <li>
                    Automated inventory tracking with SKULabs' real-time data
                  </li>
                  <li>Regular shipping audits</li>
                </ul>
              </div>
              <div className="results">
                <h3>Results</h3>
                <div className="result">
                  <p className="result-number">50%</p>
                  <p className="result-description">
                    Time Saved: Pick & pack costs reduced
                  </p>
                </div>
                <div className="result">
                  <p className="result-number">100%</p>
                  <p className="result-description">
                    Order accuracy, eliminating costly mistakes and re-shipping
                  </p>
                </div>
                <div className="result">
                  <p className="result-number">60%</p>
                  <p className="result-description">Shipping cost savings</p>
                </div>
                <div className="result">
                  <p className="result-description">
                    Reduced stockouts & improved customer satisfaction
                  </p>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </main>
      <Reviews />
    </>
  );
};

export default HoT;
