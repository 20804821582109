import React, { useState, useEffect, useRef } from "react";
import integrationsList from "../../../../data/integrationData";
import IntegrationCard from "./IntegrationCard";
import CategoryFilter from "./CategoryFilter";
import SearchBox from "./SearchBox";
import filterIcon from "assets/icons/filter.svg";
import puzzle from "assets/brand_logos/integrations/puzzle.svg";

const IntegrationCardsList: React.FC = () => {
  const [categoryFilter, setCategoryFilter] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const cardsContainerRef = useRef<HTMLDivElement>(null);

  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCategoryFilter((prev) =>
      prev.includes(value)
        ? prev.filter((category) => category !== value)
        : [...prev, value]
    );
    setSearchTerm("");

    if (cardsContainerRef.current) {
      cardsContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCategoryFilter([]);
    setShowFilters(false);
  };

  const toggleFilters = () => {
    setShowFilters((prev) => !prev);
  };

  const searchTimeout = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (cardsContainerRef.current && searchTerm) {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
      searchTimeout.current = setTimeout(() => {
        cardsContainerRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 1000);
    }
  }, [searchTerm]);

  const searchTerms = searchTerm
    .split(" ")
    .filter(Boolean)
    .map((term) => term.toLowerCase());

  const filteredData = integrationsList.filter((card) => {
    const matchesCategory =
      categoryFilter.length === 0 || categoryFilter.includes(card.category);

    const matchesSearch = searchTerms.some(
      (term) =>
        card.shortFeatures?.some((feature) =>
          feature.toLowerCase().includes(term)
        ) ||
        card.tags?.some((tag) => tag.toLowerCase().includes(term)) ||
        card.companyName.toLowerCase().includes(term) ||
        card.searchText?.toLowerCase().includes(term)
    );

    return matchesSearch || (!searchTerm && matchesCategory);
  });

  return (
    <div className="integration-container container">
      <div className={`integration-filter ${showFilters ? "show" : ""}`}>
        <CategoryFilter
          selectedCategories={categoryFilter}
          onChange={handleCategoryChange}
        />
      </div>
      <div className="cards-container" ref={cardsContainerRef}>
        <div className="filters">
          <SearchBox searchTerm={searchTerm} onChange={handleSearchChange} />
          <div
            className={`filter-button ${showFilters ? "active" : ""}`}
            onClick={toggleFilters}
          >
            <img src={filterIcon} alt="Filter" />
            {showFilters ? "Close" : "Filter"}
          </div>
          <div
            className={`filter-container mobile ${showFilters ? "show" : ""}`}
          >
            <CategoryFilter
              selectedCategories={categoryFilter}
              onChange={handleCategoryChange}
            />
          </div>
        </div>

        <div className="integration-cards-list">
          {filteredData.map((card, id) => (
            <IntegrationCard
              key={id + card.companyName}
              companyName={card.companyName}
              tags={card.tags}
              features={card.shortFeatures}
              category={card.category}
              image={card.logo}
            />
          ))}
          <IntegrationCard
            key={"404notfound"}
            companyName={""}
            tags={["Can't find what you're looking for? We have options"]}
            features={[
              "Contact us for more information on integrating.",
              "See our Remote Store integration to use FTP/SFTP",
              "Try Zapier or AirTable",
            ]}
            category={""}
            image={async () => puzzle}
          />
        </div>
      </div>
    </div>
  );
};

export default IntegrationCardsList;
