import React, { Suspense } from "react";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as Facebook } from "assets/icons/social/facebook.svg";
import { ReactComponent as X } from "assets/icons/social/x.svg";
import { ReactComponent as LinkedIn } from "assets/icons/social/linkedin.svg";
import { ReactComponent as Instagram } from "assets/icons/social/instagram.svg";
import { ReactComponent as YouTube } from "assets/icons/social/youtube.svg";

const DesktopMenu = React.lazy(() => import("./DesktopMenu"));
const MobileMenu = React.lazy(() => import("./MobileMenu"));

interface NavMenuProps {
  isMenuOpen: boolean;
  setIsMenuOpen: (isOpen: boolean) => void;
}

const NavMenu: React.FC<NavMenuProps> = ({ isMenuOpen, setIsMenuOpen }) => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });

  return (
    <Suspense fallback={<div></div>}>
      {isDesktop ? (
        <DesktopMenu />
      ) : (
        <MobileMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      )}
      <div className="social">
        <a
          href="https://www.youtube.com/@SKULabs"
          target="_blank"
          rel="noopener"
          className="social-link"
        >
          <YouTube />
        </a>
        <a
          href="https://www.instagram.com/skulabs/"
          target="_blank"
          rel="noopener"
          className="social-link"
        >
          <Instagram />
        </a>
        <a
          href="https://www.linkedin.com/company/10268879/admin/dashboard/"
          target="_blank"
          rel="noopener"
          className="social-link"
        >
          <LinkedIn />
        </a>
        <a
          href="https://www.facebook.com/people/SKULabs/61559147060308/"
          target="_blank"
          rel="noopener"
          className="social-link"
        >
          <Facebook />
        </a>
        <a
          href="https://x.com/skulabs"
          target="_blank"
          rel="noopener"
          className="social-link"
        >
          <X />
        </a>
      </div>
    </Suspense>
  );
};

export default NavMenu;
