import { useCallback } from "react";
import { Plan } from "data/pricingData";

const usePlanHelpers = (selectedPlan: string) => {
  // Функция для получения текста кнопки
  const getButtonText = useCallback(
    (planName: string) => {
      if (!selectedPlan) return "Get started now";
      if (selectedPlan.toLowerCase() !== planName.toLowerCase()) {
        return `Continue with ${planName}`;
      }
      return `Keep ${planName} Plan`;
    },
    [selectedPlan]
  );

  const renderFeatureValue = useCallback((plan: Plan, index: number) => {
    const feature = plan.features[index];

    if (feature.included !== undefined) {
      return feature.included ? "✔" : "✖";
    }

    if (feature.limit || feature.additionalCost) {
      return (
        <>
          {feature.limit && <span className="value">{feature.limit}</span>}
          {feature.additionalCost && (
            <span className="additional-cost">{feature.additionalCost}</span>
          )}
        </>
      );
    }

    return "-";
  }, []);

  return { getButtonText, renderFeatureValue };
};

export default usePlanHelpers;
