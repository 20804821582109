export type PlanFeature = {
  name: string;
  included?: boolean;
  limit?: number | string;
  additionalCost?: string;
};

export type Plan = {
  name: string;
  price: string;
  description?: string;
  code: string;
  features: PlanFeature[];
  additionalFeatures?: PlanFeature[];
};

export type PricingPlans = {
  starter: Plan;
  premium: Plan;
  enterprise: Plan;
};

const pricingPlans: PricingPlans = {
  starter: {
    name: "Starter",
    price: "$299/mo",
    description: "Everything you need to start shipping and tracking inventory",
    code: "basic2023",
    features: [
      { name: "Orders", limit: 1000, additionalCost: "+$0.20/ea" },
      { name: "Users", limit: 2, additionalCost: "+$75/ea" },
      { name: "Sales Channels", limit: 2, additionalCost: "+$25/ea" },
      { name: "Warehouses", limit: 2, additionalCost: "+$50/ea" },
      { name: "SKUs", limit: 10000, additionalCost: "+$0.15/1000" },
      { name: "Onboarding Sessions", limit: 3 },
      { name: "API Calls/Day", limit: 3 },
      { name: "Support", limit: "Live chat, Email" },
      { name: "Real-time Sync", included: true },
      { name: "Shipping", included: true },
      { name: "Barcode Scanning", included: true },
      { name: "Purchase Orders", included: false },
      { name: "Batch Picking", included: false },
      { name: "Additional Reports", included: false },
      { name: "Inventory age forecast", included: false },
      { name: "Beta feature access", included: false },
      { name: "Flash sale sync", included: false },
      { name: "Dedicated support", included: false },
      { name: "On-site", included: false },
      { name: "Customization", included: false },
    ],
  },
  premium: {
    name: "Premium",
    price: "$999/mo",
    description:
      "More sales channels, more features, and more room to grow your business",
    code: "pro2023",
    features: [
      { name: "Orders", limit: 10000, additionalCost: "+$0.10/ea" },
      { name: "Users", limit: 5, additionalCost: "+$50/ea" },
      { name: "Sales Channels", limit: 5, additionalCost: "+$25/ea" },
      { name: "Warehouses", limit: 5, additionalCost: "+$25/ea" },
      { name: "SKUs", limit: 50000, additionalCost: "+$10/1000" },
      { name: "Onboarding Sessions", limit: 6 },
      { name: "API Calls/Day", limit: 6 },
      {
        name: "Support",
        limit: "Live chat, Email, Call, Screenshare",
      },
      { name: "Real-time Sync", included: true },
      { name: "Shipping", included: true },
      { name: "Barcode Scanning", included: true },
      { name: "Purchase Orders", included: true },
      { name: "Batch Picking", included: true },
      { name: "Additional Reports", included: true },
      { name: "Inventory age forecast", included: true },
      { name: "Beta feature access", included: false },
      { name: "Flash sale sync", included: false },
      { name: "Dedicated support", included: false },
      { name: "On-site", included: false },
      { name: "Customization", included: false },
    ],
  },
  enterprise: {
    name: "Enterprise",
    price: "$1999/mo",
    description:
      "More sales channels, more features, and more room to grow your business",
    code: "enterprise2023",
    features: [
      { name: "Orders", limit: 50000, additionalCost: "+$0.05/ea" },
      { name: "Users", limit: 5, additionalCost: "+$40/ea" },
      { name: "Sales Channels", limit: 5, additionalCost: "+$20/ea" },
      { name: "Warehouses", limit: 5, additionalCost: "+$20/ea" },
      { name: "SKUs", limit: 200000, additionalCost: "+$10/1000" },
      { name: "Onboarding Sessions", limit: 9 },
      { name: "API Calls/Day", limit: 9 },
      {
        name: "Support",
        limit: "Live chat, Email, Call, Screenshare",
      },
      { name: "Real-time Sync", included: true },
      { name: "Shipping", included: true },
      { name: "Barcode Scanning", included: true },
      { name: "Purchase Orders", included: true },
      { name: "Batch Picking", included: true },
      { name: "Additional Reports", included: true },
      { name: "Inventory age forecast", included: true },
      { name: "Beta feature access", included: true },
      { name: "Flash sale sync", included: true },
      { name: "Dedicated support", included: true },
      { name: "On-site", included: true },
      { name: "Customization", included: true },
    ],
  },
};

export default pricingPlans;
