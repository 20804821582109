import LiveDemoForm from "components/Layouts/LiveDemoForm/LiveDemoForm";
import IntegrationCardsList from "components/Layouts/Integrations/IntegrationsCards/IntegrationCardsList";
import SupportSection from "components/Layouts/Support/SupportSection";
import { Helmet } from "react-helmet";

const Integrations: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Connect SKULabs to Amazon, eBay, Shopify, Etsy, and more</title>
        <meta
          name="description"
          content="Connect and Manage All Your Stores Effortlessly"
        />
      </Helmet>
      <div className="page-head container-short">
        <h1>Connect and Manage All Your Stores Effortlessly</h1>
        <p>
          The integrations you need to succeed. Connect all of your stores and
          shipping accounts to one easy-to-use dashboard, and manage all of your
          orders and inventory in one app.
        </p>
        <LiveDemoForm />
      </div>

      <main className="integration">
        <IntegrationCardsList />
      </main>
      <SupportSection />
    </>
  );
};

export default Integrations;
