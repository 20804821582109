import { CalendlyWrapper } from "components/Layouts/CalendlyWrapper/CalendlyWrapper";
import { useState } from "react";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [email, setEmail] = useState<string>("");

  return (
    <>
      {" "}
      <Helmet>
        <title>Contacts - SKULabs</title>
        <meta
          name="description"
          content="Contact the SKULabs team for more information about our order and inventory management software. We're ready to help your business grow!"
        />
      </Helmet>
      <main className="container contact-page">
        <div className="contact-page-info">
          <h2>Contact with SKulabs</h2>
          <p>
            You can contact any department of our company either by phone or by
            emailing us. For quicker assistance, please use our online form.
          </p>
          <div className="contact-links">
            <a href="mailto:support@skulabs.com" className="contact-link">
              <span className="contact-link-title">Support:</span>
              <span>support@skulabs.com</span>
            </a>
            <a href="mailto: sales@skulabs.com" className="contact-link">
              <span className="contact-link-title">Sales:</span>
              <span>sales@skulabs.com</span>
            </a>
            <a href="mailto:enterprise@skulabs.com" className="contact-link">
              <span className="contact-link-title">Enterprise:</span>
              <span>enterprise@skulabs.com</span>
            </a>
            <a href="tel:+1800243-2986" className="contact-link">
              <span className="contact-link-title">Call us at:</span>
              <span>+1 (800) 243-2986</span>
            </a>
          </div>
        </div>
        <form action=""></form>
      </main>
    </>
  );
};

export default Contact;
