import { useMemo } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import LiveDemoForm from "components/Layouts/LiveDemoForm/LiveDemoForm";
import SupportSection from "components/Layouts/Support/SupportSection";
import NotFound from "Pages/NotFound";

import dataIndustries, { IndustryPage } from "data/industriesData";

const Industries: React.FC = () => {
  const location = useLocation();
  const errorIcon = "/icons/error-icon.svg";
  const arrowTarget = "/icons/arrow-target.svg";

  const industryData: IndustryPage | undefined = useMemo(() => {
    return dataIndustries[location.pathname];
  }, [location.pathname]);

  if (!industryData) {
    return <NotFound />;
  }

  return (
    <>
      <Helmet>
        <title>{industryData.seoTitle || "SKULabs Features Overview"}</title>
        <meta
          name="description"
          content={
            industryData.seoDescription ||
            "Learn how SKULabs can help you run your ecommerce business"
          }
        />
      </Helmet>
      <div className="industries-hero">
        <div className="container">
          <div
            className={
              industryData.heroImg
                ? "industries-info"
                : "industries-info center"
            }
          >
            <h1>{industryData.heroTitle}</h1>
            <p className="description">{industryData.heroDescription}</p>
            <LiveDemoForm />
          </div>
          <video src=""></video>
          {/* {industryData.heroImg && (
            <img
              src={industryData.heroImg}
              alt={industryData.heroTitle}
              loading="lazy"
              width={536}
              height={644}
            />
          )} */}
        </div>
      </div>

      <div className="industries-metrics">
        <div className="container">
          <div className="statistic-wrapper">
            {industryData.metrics.map((metric, index) => (
              <div className="metric-item" key={index}>
                <div className="metric-value">{metric.number}</div>
                <p className="metric-description">{metric.annotation}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <main>
        <div className="industries-errors">
          <div className="container">
            <div className="industries-errors-info">
              <h2>{industryData.errorTitle}</h2>
              <p>{industryData.errorDescription}</p>
              <img
                src={industryData.errorImage}
                alt="Error"
                className="industries-errors-img"
                loading="lazy"
                width={610}
                height={368}
              />
            </div>
            <ul className="industries-errors-list">
              {industryData.errorsList.map((error, index) => (
                <li key={index} className="industries-errors-item">
                  <img
                    src={errorIcon}
                    alt="Error icon"
                    loading="lazy"
                    width={24}
                    height={24}
                  />
                  <p>
                    <strong>{error.title}: </strong>
                    {error.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="industries-benefits">
          <div className="container">
            <div className="solve-problems">
              SKULabs will solve your problems
              <img
                src={arrowTarget}
                alt="Arrow target"
                className="arrow-target"
                loading="lazy"
              />
            </div>
            <div className="live-demo">
              <hr />
              <LiveDemoForm />
              <hr />
            </div>
            <div className="industries-benefits-info">
              <h2>{industryData.benefitsTitle}</h2>
              <p>{industryData.benefitsDescription}</p>
            </div>

            <div className="industries-benefits-list">
              {industryData.benefitsGroups.map((benefitGroup, groupIndex) => (
                <div key={groupIndex} className="industries-benefits-group">
                  <div className="industries-benefits-img">
                    <img
                      src={benefitGroup.image}
                      alt="Benefit"
                      loading="lazy"
                    />
                  </div>
                  <div className="industries-benefits-annotation">
                    {benefitGroup.benefits.map((benefit, index) => (
                      <div key={index} className="industries-benefits-item">
                        <h3>{benefit.title}</h3>
                        <p>{benefit.description}</p>
                      </div>
                    ))}
                    <Link to={benefitGroup.link} className="cta">
                      {benefitGroup.linkText}
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
      <SupportSection />
    </>
  );
};

export default Industries;
