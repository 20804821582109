export const Redirecting = () => {
  return (
    <div className="redirecting">
      <div className="lds-facebook">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p className="title">
        {" "}
        <strong>Redirecting...</strong>
      </p>
    </div>
  );
};

export default Redirecting;
