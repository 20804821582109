import SupportSection from "components/Layouts/Support/SupportSection";
import { paths } from "helpers/paths";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";


export const NotFound = () => {
  const errorImg = "/images/error404.webp";

  return (
    <>
      <Helmet>
        <title>SKULabs - Page Not Found</title>
        <meta
          name="description"
          content="This page could not be found."
        />
        <meta name="robots" content="noindex, follow"></meta>
      </Helmet>
      <section className="notfound container">
        <img src={errorImg} alt="Error Illustration" />
        <p className="small-title">
          Sorry, the page you are looking for could not be found
        </p>
        <Link to={paths.root} className="cta big">
          Let's try something else
        </Link>
      </section>
      <SupportSection />
    </>
  );
};

export default NotFound;
