import LogoCarousel from "components/Layouts/LogoCarousel/LogoCarousel";
import { Link } from "react-router-dom";

export const keysForCarousel1 = [
  "shopifypos",
  "shopify",
  "amazon",
  "ebay",
  "walmart",
  "etsy",
  "bigCommerce",
  "magento",
  "shopifyPlus",
  "indiegogo",
  "square",
  "kickstarter",
];
export const keysForCarousel2 = [
  "paypal",
  "stripe",
  "fedex",
  "usps",
  "ups",
  "dhl",
  "easypost",
  "canadapost",
  "amazonShipping",
  "ontrac",
  "flexport",
];

const ProLogoCarousel = () => {
  return (
    <>
      <div className="landing-integrations-content container">
        <h2>Integrated with widely-used eCommerce services</h2>
        <div className="info">
          <p>
            Experience seamless transactions with our service, seamlessly
            integrated with popular platforms for your ultimate convenience.
            Efficiently manage your goods and supplies
          </p>
          <Link className="link-more" to="/integrations">
            View All Integrations{" "}
            <img
              className="arrow"
              src="icons/links-arrow.svg"
              alt="Arrow"
              width="24"
              height="24"
            />
          </Link>
        </div>
      </div>

      <div className="landing-integrations-slider">
        <LogoCarousel direction="left" keysToShow={keysForCarousel1} />
        <LogoCarousel direction="right" keysToShow={keysForCarousel2} />
      </div>
    </>
  );
};

export default ProLogoCarousel;
