import { useLocation } from "react-router-dom";
import PricingNew from "./PricingNew";
import PricingTable from "./PricingTable";

const PricingRoute = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const plan = searchParams.get("plan");

  if (plan) {
    return <PricingTable />;
  }

  return <PricingNew />;
};

export default PricingRoute;
