import { Link } from "react-router-dom";
import NavMenu from "components/Layouts/Header/NavMenu/NavMenu";
import { ReactComponent as SkulabsLogo } from "assets/logos/skulabs-logo.svg";

interface HeaderProps {
  isMenuOpen: boolean;
  setIsMenuOpen: (isOpen: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ isMenuOpen, setIsMenuOpen }) => {
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header>
      <div className="container">
        <div className="top-level">
          <a href="tel:+18002432986" className="phone-number">
            Call us at: <span className="accent">+1 (800) 243-2986</span>
          </a>
          <Link to="/" className="logo" onClick={closeMenu}>
            <SkulabsLogo />
          </Link>
          <div className="login">
            <Link
              to="https://login.skulabs.com"
              className="cta small"
              target="_blank"
              rel="noopener"
            >
              Log In
            </Link>
          </div>
        </div>
        <div className="bottom-level">
          <NavMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        </div>
      </div>
    </header>
  );
};

export default Header;
