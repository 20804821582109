import { useEffect, useState } from "react";
import skulabs from "assets/brand_logos/square/skulabs-square.svg";
import leftFlow from "assets/images/integrations/left-flow.svg";
import rightFlow from "assets/images/integrations/right-flow.svg";

interface IntegrationLogosProps {
  companyMiniLogo?: () => Promise<string>;
  companyName?: string;
}

const IntegrationLogos: React.FC<IntegrationLogosProps> = ({
  companyMiniLogo,
  companyName,
}) => {
  const [logoSrc, setLogoSrc] = useState<string | null>(null);

  useEffect(() => {
    if (companyMiniLogo) {
      companyMiniLogo().then((src) => setLogoSrc(src));
    }
  }, [companyMiniLogo]);

  return (
    <div className="integration-logos">
      <div className="integration-company">
        {logoSrc ? (
          <img src={logoSrc} alt={companyName} loading="lazy" />
        ) : (
          <div>Loading...</div>
        )}
        <img
          src={leftFlow}
          alt="flow"
          className="flow flow-left"
          loading="lazy"
        />
      </div>
      <div className="plus"></div>
      <div className="integration-company">
        <img src={skulabs} alt="Skulabs" loading="lazy" />
        <img
          src={rightFlow}
          alt="flow"
          className="flow flow-right"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default IntegrationLogos;
