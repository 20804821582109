import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface IntegrationCardProps {
  companyName: string;
  tags: string[];
  features: string[];
  category: string;
  image: () => Promise<string>;
}

const IntegrationCard: React.FC<IntegrationCardProps> = ({
  companyName,
  tags,
  features,
  image,
}) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    image().then((src) => setImageSrc(src));
  }, [image]);

  const link = `/integrations/${companyName.toLowerCase().replace(" ", "")}`;

  return (
    <Link
      to={
        companyName
          ? link
          : "https://0ksofc80okw.typeform.com/to/QFRN4RH6?typeform-source=www.skulabs.com"
      }
      className="integration-card"
    >
      <div className="integration-card-info">
        {imageSrc ? (
          <img src={imageSrc} alt={companyName} className="integration-logo" />
        ) : (
          <div>Loading...</div>
        )}
        <div className="tags-list">
          {tags.map((tag, index) => (
            <span key={index} className="tag">
              {tag}
            </span>
          ))}
        </div>
        <ul className="integration-features-short">
          {features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
      </div>
      <p className="integration-link">
        {companyName ? "About Integration" : "Contact Us"}
      </p>
    </Link>
  );
};

export default IntegrationCard;
