import Input from "components/Common/Inputs/Input";
import React, { useState } from "react";

type SignupModalProps = {
  plan: string;
  code: string;
  isVisible: boolean;
  onClose: () => void;
};

const SignupModal: React.FC<SignupModalProps> = ({
  plan,
  code,
  isVisible,
  onClose,
}) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  if (!isVisible) return null;

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setEmail(e.target.value);
    if (e.target.value) {
      setError("");
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    if (!email) {
      event.preventDefault();
      setError("Email is required");
    }
  };

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="modal" onClick={handleOverlayClick}>
      <div className="modal-content">
        <h3>
          Sign Up for{" "}
          <span className="accent">
            {plan.charAt(0).toUpperCase() + plan.slice(1)} Plan
          </span>
        </h3>

        <form
          method="POST"
          action="https://next.skulabs.com/s/api/account/signup_form"
          onSubmit={handleSubmit}
        >
          <Input
            label="Email"
            name="email"
            type="email"
            value={email}
            placeholder="Enter your email"
            onChange={handleEmailChange}
            error={error}
          />

          <input type="hidden" name="firstName" value="John" />
          <input type="hidden" name="lastName" value="Doe" />
          <input type="hidden" name="phone" value="123456789" />
          <input type="hidden" name="plan" value={code} />

          <p>
            I agree to the SKULabs{" "}
            <a href="/terms" className="link accent">
              Terms of Use
            </a>{" "}
            and{" "}
            <a href="/privacy" className="link accent">
              Privacy Policy
            </a>
            .
          </p>
          <div className="buttons">
            <button onClick={onClose} className="cta cta-secondary">
              Close
            </button>
            <button type="submit" className="cta">
              Start right now
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupModal;
