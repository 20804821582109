import React, { useEffect } from "react";
import { useParams, Navigate, Route } from "react-router-dom";
import Redirecting from "Pages/Redirecting";
import paths from "helpers/paths";

export const DynamicRedirect = () => {
  const { slug } = useParams();
  const newPath = `/integrations/${slug}`;
  return <Navigate to={newPath} replace />;
};

export const ExternalRedirect = ({ to }: { to: string }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return <Redirecting />;
};

export const legacyRedirectsData = [
  ["/signup", paths.pricing],
  ["/insights", paths.insightsReporting],
  ["/mobile", paths.features],
  ["/pack", paths.shipping],
  ["/pick", paths.orderPicking],
  ["/ship", paths.shipping],
  ["/square", paths.square],
  ["/walmart", paths.walmart],
  ["/3dcart", paths.shift4shop],
  ["/amazon", paths.amazon],
  ["/bigcommerce", paths.bigCommerce],
  ["/ebay", paths.ebay],
  ["/etsy", paths.etsy],
  ["/jet", paths.amazon],
  ["/magento", paths.magento],
  ["/shopify", paths.shopify],
  ["/woocommerce", paths.woocommerce],
  ["/integrations/3dcart", paths.shift4shop],
];

export const externalRedirectsData = [
  ["/qa", paths.qa],
  ["/api", paths.api],
  ["/instagram", paths.instagram],
  ["/support", paths.support],
  ["/forum", paths.support],
  ["/feedback", paths.support],
  ["/status", paths.status],
  ["/calendly", paths.calendly],
  ["/react-www", paths.reactwww],
  ["/careers", paths.careers],
  ["/hiring", paths.careers],
  ["/jobs", paths.careers],
  [paths.supportLinks.inventoryGuide, paths.newSupportLinks.inventoryGuide],
  [
    paths.supportLinks.orderFulfillmentGuide,
    paths.newSupportLinks.orderFulfillmentGuide,
  ],
  [
    paths.supportLinks.batchPickingGuide,
    paths.newSupportLinks.batchPickingGuide,
  ],
  [paths.supportLinks.shippingGuide, paths.newSupportLinks.shippingGuide],
  [
    paths.supportLinks.purchaseOrderGuide,
    paths.newSupportLinks.purchaseOrderGuide,
  ],
  [
    paths.supportLinks.insightsReportingGuide,
    paths.newSupportLinks.insightsReportingGuide,
  ],
  [
    paths.supportLinks.contactManagementGuide,
    paths.newSupportLinks.contactManagementGuide,
  ],
  [paths.supportLinks.printingGuide, paths.newSupportLinks.printingGuide],
];

export const handleSpecialRedirects = (pathname: string) => {
  if (pathname.startsWith("/blog")) {
    const blogPath = pathname.substring(5);
    const externalUrl = `${paths.blog}${blogPath}`;
    return <ExternalRedirect to={externalUrl} />;
  }

  if (
    pathname.startsWith("/features/inventory") &&
    pathname !== paths.inventoryManagement
  ) {
    return <Navigate to={paths.inventoryManagement} replace />;
  }

  return null;
};

export const handleRedirects = (
  pathname: string,
  navigate: (path: string) => void
) => {
  // Special redirects
  const specialRedirect = handleSpecialRedirects(pathname);
  if (specialRedirect) return specialRedirect;

  // External redirects
  const externalRedirect = externalRedirectsData.find(([oldPath]) =>
    pathname.startsWith(oldPath)
  );
  if (externalRedirect) {
    return externalRedirect[1];
  }

  // Legacy redirects
  const internalRedirect = legacyRedirectsData.find(
    ([oldPath]) => pathname === oldPath
  );
  if (internalRedirect) {
    navigate(internalRedirect[1]);
    return null;
  }

  return null;
};
