import { useMemo } from "react";

import Capability from "./Capability";
import {
  channelsData,
  shippingsData,
  accountingData,
  threeplData,
  othersData,
} from "../../../data/integrationData";

interface CapabilitiesProps {
  category: string;
  companyName?: string;
}

const Capabilities: React.FC<CapabilitiesProps> = ({
  category,
  companyName,
}) => {
  const capabilities = useMemo(() => {
    switch (category) {
      case "Channel":
        return channelsData;
      case "Shipping":
        return shippingsData;
      case "Accounting/Payments":
        return accountingData;
      case "3PL":
        return threeplData;
      case "Other":
        return othersData;
      default:
        return [];
    }
  }, [category]);

  return (
    <>
      <h2>
        {category === "Other" || category === "Accounting/Payments" ? (
          <>
            Benefits of <span className="accent">SKULabs</span>{" "}
          </>
        ) : (
          <>
            How <span className="accent">{companyName}</span> works with{" "}
            <span className="accent">SKULabs</span>
          </>
        )}
      </h2>
      <div className="capabilities">
        {capabilities.map((capability, index) => (
          <Capability
            key={index}
            iconSrc={capability.iconSrc}
            title={capability.title}
            description={capability.description}
          />
        ))}
      </div>
    </>
  );
};

export default Capabilities;
