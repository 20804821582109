import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import PricingPlan from "./PricingPlan";
import LiveDemoForm from "components/Layouts/LiveDemoForm/LiveDemoForm";
import SupportSection from "components/Layouts/Support/SupportSection";
import TargetIntegration from "components/Layouts/TargetIntegrations/TargetIntegration";
import FAQ from "components/Layouts/Faq/Faq";
import usePlanHelpers from "hooks/usePlanHelpers";
import pricingPlans from "data/pricingData";
import { faqPricingData } from "data/faqData";

const PricingTable: React.FC = () => {
  const { starter, premium, enterprise } = pricingPlans;
  const location = useLocation();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  let selectedPlan = searchParams.get("plan") || "";

  const { getButtonText, renderFeatureValue } = usePlanHelpers(selectedPlan);

  const plans = [starter, premium, enterprise];

  return (
    <>
      {/* <Helmet>
        <title>Plans & Pricing - SKULabs</title>
        <meta
          name="description"
          content="Compare SKULabs pricing plans and features to choose the best fit for your business."
        />
      </Helmet> */}

      <main>
        {/* <div className="page-head container-short">
          <h1>Prices for our products</h1>
          <p className="description">
            Optimize your inventory processes with our intuitive solution.
            Choose the SKULabs plan that aligns with your business goals and
            watch your efficiency soar. It’s time to take control of your
            inventory like never before.
          </p>
          <div className="tags">
            <div className="tag">30 day money back guarantee</div>
            <div className="tag">No contracts or onboarding fees</div>
          </div>
          <LiveDemoForm />
        </div> */}

        <div className="plans-container">
          <div className="plans-table container">
            <div className="plan plan-features">
              <div className="plan-info">
                <p className="plan-name">Features</p>
                <p className="plan-description">
                  Pricing valid for ACH/debit only. All other payment methods
                  are subject to a 3% surcharge.
                </p>
              </div>
              <div className="plan-body">
                {starter.features.map((feature) => (
                  <p className="plan-value" key={feature.name}>
                    {feature.name}
                  </p>
                ))}
              </div>
            </div>

            {plans.map((plan) => (
              <PricingPlan
                key={plan.name}
                plan={plan}
                isHighlighted={selectedPlan === plan.code.toLowerCase()}
                getButtonText={getButtonText}
                renderFeatureValue={renderFeatureValue}
              />
            ))}
          </div>

          <FAQ items={faqPricingData} />
        </div>

        <TargetIntegration />
        <SupportSection />
      </main>
    </>
  );
};

export default React.memo(PricingTable);
