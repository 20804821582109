import { ReactElement, useState } from "react";
import "./styles/index.scss";
import Header from "components/Layouts/Header/Header";
import Footer from "components/Layouts/Footer/Footer";

interface ISkulabsDotCom {
  children: ReactElement | ReactElement[];
}

const SkulabsDotCom = (props: ISkulabsDotCom) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <>
      <Header isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <div className="wrapper">{props.children}</div>
      <Footer />
    </>
  );
};

export default SkulabsDotCom;
