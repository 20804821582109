import React from "react";

interface FeatureItem {
  title: string;
  description: string;
}

interface IntegraionFeaturesSectionProps {
  data?: FeatureItem[];
}

const IntegraionFeaturesSection: React.FC<IntegraionFeaturesSectionProps> = ({
  data,
}) => {
  return data?.length ? (
    <div className="integration-features">
      <h2>
        Maximize Efficiency with One <span className="accent">All-in-One</span>{" "}
        Business Tool
      </h2>
      {data.map((item, index) => (
        <div key={index} className="feature-item">
          <p className="title">{item.title}</p>
          <p className="description">{item.description}</p>
        </div>
      ))}
    </div>
  ) : null;
};

export default IntegraionFeaturesSection;
