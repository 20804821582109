export const paths = {
  // Main routes
  root: "/",
  about: "/about",
  partners: "/integrations",
  integrations: "/integrations",
  features: "/features",
  hardware: "/hardware",
  inventory: "/inventory",
  inventoryPricing: "/inventory/pricing",
  hardwareMobileDevices: "/hardware/mobile-devices",
  hardwareBarcodeScanners: "/hardware/barcode-scanners",
  hardwareLabelPrinters: "/hardware/label-printers",
  hardwareScales: "/hardware/scales",
  hardwareNetworkingGear: "/hardware/networking-gear",
  reviews: "/reviews",
  connect: "/connect",
  book: "/demo",
  call: "/call",
  talk: "/talk", // Retired talk October 2024
  contacts: "/contacts",
  faq: "/faq",
  skulabsPro: "/pro",

  caseStudies: {
    hot: "hot",
  },

  termsOfUse: "/terms-of-use",
  privacyPolicy: "/privacy-policy",
  gdpr: "/gdpr",
  ccpa: "/ccpa",
  redirecting: "/redirecting",
  changelog: "/changelog",
  pricing: "/pricing",
  ai: "/ai",

  useCases: "/use-cases", // TODO
  skulabsSignup: "/skulabsSignup", // TODO

  sitemap: "/sitemap", // ? need create good sitemapData

  health411: "/health411", // ??????
  process: "/process", // ?????
  stitchlabsAlternative: "/stitchlabs-alternative", // ???????
  thanks: "/thanks", // ???????

  careers: "https://apply.workable.com/skulabs/", //* Redirecting

  // Support links
  supportLinks: {
    inventoryGuide: "/support/inventory/", //* Redirecting
    orderFulfillmentGuide: "/support/orders/", //* Redirecting
    batchPickingGuide: "/support/batches/", //* Redirecting
    shippingGuide: "/support/shipping/", //* Redirecting
    purchaseOrderGuide: "/support/purchase-orders/", //* Redirecting
    insightsReportingGuide: "/support/insights/", //* Redirecting
    contactManagementGuide: "/support/contacts/", //* Redirecting
    printingGuide: "/support/printing/", //* Redirecting
    questionsAndAnswers: "/qa/", //* Redirecting
  },

  newSupportLinks: {
    inventoryGuide:
      "https://help.skulabs.com/en/articles/900952-inventory-management",
    orderFulfillmentGuide:
      "https://help.skulabs.com/en/articles/900955-the-orders-page",
    batchPickingGuide:
      "https://help.skulabs.com/en/articles/4043127-how-can-i-start-using-the-batch-feature",
    shippingGuide:
      "https://help.skulabs.com/en/articles/900939-shipping-manifests",
    purchaseOrderGuide:
      "https://help.skulabs.com/en/articles/900962-the-purchase-orders-page",
    insightsReportingGuide:
      "https://help.skulabs.com/en/articles/4044132-insights-feature-overview",
    contactManagementGuide:
      "https://help.skulabs.com/en/articles/6366154-how-do-i-contact-skulabs",
    printingGuide:
      "https://help.skulabs.com/en/articles/3972772-printing-manual-fulfillment-labels",
    questionsAndAnswers: "/qa/",
  },

  // Industries
  industries: "/industries",

  industriesLinks: {
    industrial: "/industries/industrial",
    fashion: "/industries/fashion",
    automotive: "/industries/automotive",
    threepl: "/industries/3pl",
    jewelry: "/industries/jewelry",
  },

  // Individual features
  inventoryManagement: "/features/inventory-management",
  orderPicking: "/features/picking",
  shipping: "/features/shipping",
  batchPicking: "/features/batch-picking",
  orderManagement: "/features/order-management",
  pointOfSale: "/features/point-of-sale",
  purchaseOrder: "/features/purchase-orders",
  warehouseManagement: "/features/warehouse-management",
  catalogManagement: "/features/catalog-management",
  insightsReporting: "/features/insights",

  // Tools
  inventoryTemplate: "/inventory-template",
  shippingTemplate: "/shipping-label-template",

  // Integrations
  shift4shop: "/integrations/shift4shop",
  bigCommerce: "/integrations/bigcommerce",
  magento: "/integrations/magento",
  skulabsShopify: "/integrations/shopify",
  shopify: "/integrations/shopify",
  shopifyPlus: "/integrations/shopifyPlus",
  woocommerce: "/integrations/woocommerce",
  amazon: "/integrations/amazon",
  ebay: "/integrations/ebay",
  etsy: "/integrations/etsy",
  walmart: "/integrations/walmart",
  square: "/integrations/square",
  shipstation: "/integrations/shipstation",
  skulabsAmazon: "/integrations/amazon",
  skulabsEtsy: "/integrations/etsy",
  duoplane: "/integrations/duoplane",
  quickbooks: "/integrations/quickbooks",

  // Inventory guides
  academyList: "/academy",

  academy: {
    benefitsOfInventoryManagementSystems: "/inventory-management-software",
    inventoryManagementForSmallBusiness:
      "/inventory-management-for-small-business",
    whatIsMultichannelInventoryControl: "/multichannel-inventory-control",
    introductionToInventoryForecasting: "/inventory-forecasting",
    introductionToCycleCounts: "/cycle-counts",
    serializedInventoryManagement: "/serialized-inventory-management",
    shippingAndReceivingInventory: "/features/shipping",
  },

  // Global skulabs routes
  skulabsSignupForm: "https://next.skulabs.com/s/api/account/signup_form",
  activateLocal: "https://app.local.test.skulabs.com/get-started#activate",
  activate: "https://app.skulabs.com/get-started#activate",
  activateNext: "https://next.skulabs.com/get-started#activate",
  activateCanary: "https://canary.skulabs.com/get-started#activate",
  signup: "https://skulabs.com/pricing",
  login: "https://login.skulabs.com/go/SKULabs",
  inventorySupport: "https://help.my.skulabs.com/en/",
  inventoryLogin: "https://login.skulabs.com/go/Inventory",
  typeform:
    "https://0ksofc80okw.typeform.com/to/QFRN4RH6?typeform-source=www.skulabs.com",
  blog: "https://blog.skulabs.com/",
  instagram: "https://www.instagram.com/skulabs/",
  support: "https://help.skulabs.com/",
  api: "https://skulabs.mintlify.app/",
  legacyLogin: "https://app.skulabs.com/login", // Unused as of September 2023

  qa: "https://help.skulabs.com/en/collections/2163716-faq",
  status: "https://skulabs.statuspage.io/",
  calendly: "https://calendly.com/skulabs/",
  reactwww: "https://react-www.skulabs.com",
  supportPro: "https://help.skulabs.com/en/",
  supportInventory: "https://help.my.skulabs.com/en/",
};

export default paths;
