interface CategoryFilterProps {
  selectedCategories: string[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CategoryFilter: React.FC<CategoryFilterProps> = ({
  selectedCategories,
  onChange,
}) => {
  const categories = [
    "Channel",
    "Shipping",
    "3PL",
    "Accounting/Payments",
    "Other",
  ];

  return (
    <>
      <p className="title">Filter:</p>
      <div className="integration-filter-list">
        {categories.map((category) => (
          <div key={category} className="checkbox">
            <input
              type="checkbox"
              id={category.toLowerCase()}
              value={category}
              checked={selectedCategories.includes(category)}
              onChange={onChange}
            />
            <label htmlFor={category.toLowerCase()}>{category}</label>
          </div>
        ))}
      </div>
    </>
  );
};

export default CategoryFilter;
