interface FAQContentText {
  type: "text";
  content: string;
}

interface FAQContentImage {
  type: "image";
  content: string;
  alt?: string;
}

type FAQContent = FAQContentText | FAQContentImage;

export interface FAQItemProps {
  question: string;
  answer: FAQContent[];
  category?: string;
}
export const faqPricingData: FAQItemProps[] = [
  {
    question: "Are there any contracts or agreements?",
    answer: [
      {
        type: "text",
        content:
          "Our founder is adamant against annual contracts. All of our plans bill on a month-to-month basis with no long-term contracts or obligations. Your success is our success.",
      },
    ],
  },
  {
    question: "What counts as a user?",
    answer: [
      {
        type: "text",
        content:
          "Every team member that needs access to SKULabs for inventory management or order fulfillment should have their own user profile within your account.",
      },
    ],
  },
  {
    question: "How many devices can each user have?",
    answer: [
      {
        type: "text",
        content:
          "A user can log into as many devices as they need. Each user is rate limited in a way that prevents concurrent abuse of the same user profile within your account.",
      },
    ],
  },
  {
    question: "How are order counts determined?",
    answer: [
      {
        type: "text",
        content:
          "Any order created or imported for forecasting, inventory, fulfillment, or other purposes counts as a paid order and will be billed at the end of the month at the rate above. FBA/WFS orders are billed at the reduced analytic rate of $0.01/order generally.",
      },
    ],
  },
];

const faqPageData: FAQItemProps[] = [
  {
    category: "General Questions",
    question: "What is SKULabs Pro?",
    answer: [
      {
        type: "text",
        content:
          "SKULabs Pro is a warehouse management system with inventory management, order picking, and shipping label purchase designed to help e-commerce businesses streamline their operations. It integrates inventory management, order processing, and shipping into a single platform.",
      },
    ],
  },
  {
    category: "General Questions",
    question: "Who is SKULabs Pro designed for?",
    answer: [
      {
        type: "text",
        content:
          "SKULabs is ideal for fast growing e-commerce businesses, subsidiaries, retailers, manufacturers, and wholesalers who manage inventory across multiple sales channels and need efficient order fulfillment processes.",
      },
    ],
  },
  {
    category: "General Questions",
    question: "What sales channels does SKULabs support?",
    answer: [
      {
        type: "text",
        content:
          "SKULabs Pro integrates with a wide range of sales channels, including Shopify, Amazon, eBay, WooCommerce, BigCommerce, and more. You can find a complete list of supported integrations on our website.",
      },
    ],
  },
  {
    category: "General Questions",
    question: "How does SKULabs help prevent overselling?",
    answer: [
      {
        type: "text",
        content:
          "SKULabs syncs inventory levels across all connected sales channels in real-time with an easy to use allocation rule system to fine-tune where you sell your last unit of inventory, preventing common order defects related to overselling. Your stock levels are always up-to-date, helping to prevent overselling and stockouts.",
      },
    ],
  },
  {
    category: "Features and Functionality",
    question: "What inventory management features does SKULabs offer?",
    answer: [
      {
        type: "text",
        content:
          "SKULabs offers multi-channel inventory sync, bundling and kitting, barcode scanning, stock audits, and cycle counts. These features help businesses maintain accurate inventory levels and improve warehouse efficiency.",
      },
    ],
  },
  {
    category: "Features and Functionality",
    question: "Can SKULabs handle returns and exchanges?",
    answer: [
      {
        type: "text",
        content:
          "Yes, SKULabs includes features for managing returns and exchanges. You can process RMAs, email shipping labels, restock items, and track the reasons for returns, making it easier to manage reverse logistics. SKULabs even syncs in returns made on Shopify automatically restocking inventory when your team uses the restock option in Shopify.",
      },
    ],
  },
  {
    category: "Features and Functionality",
    question: "Does SKULabs support multiple warehouses?",
    answer: [
      {
        type: "text",
        content:
          "Absolutely. SKULabs allows businesses to manage inventory including replenishment orders and transfer orders between multiple warehouses. Fulfill orders from multiple warehouse locations even globally to reduce shipping costs.",
      },
    ],
  },
  {
    category: "Features and Functionality",
    question: "What shipping carriers does SKULabs integrate with?",
    answer: [
      {
        type: "text",
        content:
          "SKULabs integrates with major shipping carriers such as UPS, USPS, FedEx, DHL, and more. This integration streamlines the process of creating shipping labels, comparing rates, and tracking shipments.",
      },
    ],
  },
  {
    category: "Features and Functionality",
    question: "How does SKULabs handle purchase orders?",
    answer: [
      {
        type: "text",
        content:
          "SKULabs allows you to create and manage purchase orders directly from the platform. You can track supplier inventory levels, automatically place orders, email suppliers access to your supplier portal, receive inventory, and maintain supplier accountability with our strong system of record keeping.",
      },
    ],
  },
  {
    category: "Pricing and Plans",
    question: "What are the pricing options for SKULabs?",
    answer: [
      {
        type: "text",
        content:
          "SKULabs offers several pricing plans to accommodate businesses of different sizes and needs. For detailed pricing information and to find the plan that’s right for you, please visit our pricing page or contact our sales team.",
      },
    ],
  },
  {
    category: "Pricing and Plans",
    question: "Can I get started with zero risk?",
    answer: [
      {
        type: "text",
        content:
          "Yes, SKULabs offers a 30 day money back guarantee and no annual contracts. All pricing is simple month to month.",
      },
    ],
  },
  {
    category: "Pricing and Plans",
    question: "Are there any setup fees?",
    answer: [
      {
        type: "text",
        content:
          "There are no setup fees required to use SKULabs. Our US-based team will help you onboard to our platform and continue to support you for years to come. We do not outsource our support or change which team supports you after you finish onboarding unlike most platforms who promise excellent support but shift you off-shore once you’re locked in with a multi-year contract.",
      },
    ],
  },
  {
    category: "Support and Resources",
    question: "What kind of support does SKULabs offer?",
    answer: [
      {
        type: "text",
        content:
          "We provide comprehensive support, including a knowledge base, email support, and live chat. Our support team is dedicated to helping you make the most of SKULabs.",
      },
    ],
  },
  {
    category: "Support and Resources",
    question: "How do I get started with SKULabs?",
    answer: [
      {
        type: "text",
        content:
          "Getting started with SKULabs is easy. Simply sign up for a free trial on our website, and our team will guide you through the setup process. We also offer demo sessions to show you how SKULabs can benefit your business.",
      },
    ],
  },
  {
    category: "Support and Resources",
    question: "Does SKULabs offer training for new users?",
    answer: [
      {
        type: "text",
        content:
          "Yes, we offer live training sessions and onboarding assistance for new users.",
      },
    ],
  },
  {
    category: "Security and Data",
    question: "How does SKULabs protect my data?",
    answer: [
      {
        type: "text",
        content:
          "We use industry-standard best practices to protect your data. We are regularly audited automatically, internally, and by partners like Amazon, Shopify, Etsy, eBay, and more.",
      },
    ],
  },
  {
    category: "Security and Data",
    question: "Can I export my data from SKULabs?",
    answer: [
      {
        type: "text",
        content:
          "Yes, SKULabs allows you to export your data in various formats. This flexibility ensures that you can maintain records and use your data as needed. Our JSON-based REST API with OpenAPI specification offers all of the same data that runs our app so you have complete data portability.",
      },
    ],
  },
];

export default faqPageData;
