interface CapabilityProps {
  iconSrc: string;
  title: string;
  description: string;
}

const Capability: React.FC<CapabilityProps> = ({
  iconSrc,
  title,
  description,
}) => {
  return (
    <div className="capabilitie">
      <img src={iconSrc} alt="icon" />
      <div className="capabilities-info">
        <h3 className="title">{title}</h3>
        <p className="description">{description}</p>
      </div>
    </div>
  );
};

export default Capability;
