import { Helmet } from "react-helmet";
import reviewsData from "data/reviewsData";
import ReviewsSlider from "components/Layouts/Reviews/ReviewsSlider";
import SupportSection from "components/Layouts/Support/SupportSection";

const images = {
  starIcon: "icons/star.svg",
  linkArrow: "icons/links-arrow.svg",
  shopifyLogo: "logos/shopify.svg",
  capterraLogo: "logos/capterra.svg",
  getAppLogo: "logos/getapp.svg",
  navigationArrow: "icons/slider-arrow.svg",
  defaultAvatar: "icons/default-avatar.svg",
};

export const raitingCompanies = [
  {
    href: "https://apps.shopify.com/skulabs/reviews",
    logo: images.shopifyLogo,
    name: "Shopify",
    annotation: "Exceptional Customer Experience",
    stars: 4.9,
  },
  {
    href: "https://www.capterra.com/p/148441/SKULabs/reviews/",
    logo: images.capterraLogo,
    name: "Capterra",
    annotation: "Highly Rated by Experts",
    stars: 4.6,
  },
  {
    href: "https://www.getapp.com/industries-software/a/skulabs/reviews/",
    logo: images.getAppLogo,
    name: "GetApp",
    annotation: "Trusted by Professionals",
    stars: 4.6,
  },
];

export const StarRating = ({ stars }: { stars: number }) => (
  <div
    className="testimonial-rating"
    aria-label={`Rating: ${stars} out of 5 stars`}
  >
    {[...Array(stars)].map((_, index) => (
      <img
        key={index}
        src={images.starIcon}
        alt="Filled Star"
        width="24px"
        height="24px"
      />
    ))}
  </div>
);

const Reviews = () => {
  return (
    <>
      <Helmet>
        <title>SKULabs - Customer Reviews</title>
        <meta
          name="description"
          content="Let other merchants know how our app is working for you by leaving a review"
        />
      </Helmet>
      <div className="container">
        <h1>We appreciate Your feedback</h1>
        <div className="testimonial">
          <div className="testimonial-companies">
            {raitingCompanies.map((company) => (
              <a
                key={company.href}
                href={company.href}
                className="testimonial-company"
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`Read reviews on ${company.name}`}
              >
                <img
                  src={company.logo}
                  alt={`${company.name} logo`}
                  loading="lazy"
                  width="100px"
                  height="auto"
                />
                <p className="testimonial-annotation">{company.annotation}</p>
                <div className="stars-container">
                  {company.stars} <StarRating stars={5} />
                </div>
              </a>
            ))}
          </div>

          <ReviewsSlider reviewsData={reviewsData} />
        </div>
      </div>
      <SupportSection />
    </>
  );
};

export default Reviews;
