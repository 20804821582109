import { Link } from "react-router-dom";
import featureData, { FeatureContent } from "data/featuresData";
import paths from "helpers/paths";

interface FeaturesListProps {
  featureList?: FeatureContent[];
}

const FeaturesList: React.FC<FeaturesListProps> = ({ featureList }) => {
  const feature = featureList ?? featureData[paths.features]?.content;

  return (
    <div className="pricing-features container">
      <h2>Application Features</h2>
      <div className="features-items capabilities">
        {feature.map((featureItem, index) => (
          <div className="feature-item capabilitie" key={index}>
            {featureItem.icon && (
              <img
                src={featureItem.icon}
                alt={featureItem.title}
                width="48"
                height="48"
              />
            )}
            <div className="capabilities-info">
              {featureItem.link ? (
                <Link to={featureItem.link}>
                  <h3 className="feature-title title">{featureItem.title}</h3>
                </Link>
              ) : (
                <h3 className="feature-title title">{featureItem.title}</h3>
              )}
              <p className="feature-description">{featureItem.description}</p>
              {featureItem.link && (
                <Link className="link-more" to={featureItem.link}>
                  About Feature{" "}
                  <img
                    className="arrow"
                    src="icons/links-arrow.svg"
                    alt="Arrow"
                    width="24"
                    height="24"
                  />
                </Link>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturesList;
