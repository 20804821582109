interface SearchBoxProps {
  searchTerm: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchBox: React.FC<SearchBoxProps> = ({ searchTerm, onChange }) => {
  return (
    <input
      type="text"
      id="search"
      value={searchTerm}
      onChange={onChange}
      placeholder="Enter company name"
      className="search-companies"
    />
  );
};

export default SearchBox;
