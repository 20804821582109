const reviewsData = [
  {
    logo: "/images/reviewers/house-of-tinkas-logo.svg",
    avatar: "/images/reviewers/house-of-tinkas-product.webp",
    description:
      "Once SKULabs came into play, it helped me to be able to control my warehouse. I would say, typical shipping day back when we didn't have SKULabs might have taken me maybe three or four hours. Now, I get it done in half the time, maybe a little less than that. It was a smooth transition, I will say, and I was thankful for it because I needed it.",
    position: "Warehouse Manager,",
    name: "Teran Victor",
    caseStudyLink: "/hot",
  },
  {
    logo: "/images/reviewers/canteros-logo.webp",
    avatar: "/images/reviewers/canteros-product.webp",
    description:
      "In just over a year we have increased our businesses revenue by over 50% and our net profit by a further 17% SKULabs is a intuitive, powerful ERP tool that doesn't cost an ERP sized fortune. My entire team utilise SKULabs from varying levels and departments and it is fantastic. The support is always quick and friendly.",
  },
  {
    logo: "/images/reviewers/revvl-health-logo.webp",
    avatar: "/images/reviewers/revvl-health-product.webp",
    description:
      "We wanted to share our experience with SKULabs software, as we've been proud users since June 2019. Running a small team, it's paramount for us to have inventory management software that not only streamlines our operations but also enhances our customer service.",
  },
  {
    logo: "/images/reviewers/crosspore-logo.svg",
    avatar: "/images/reviewers/crosspore-product.webp",
    description:
      "SKUlabs is a great fulfillment software that can grow with the complexity of the business. It has the ability to manage inventory well, create efficient fulfillment processes, and if they don't have the solution for something they have a strong team that will work with you to develop the solution or put something in place that works while development catches up. ",
  },
  {
    logo: "/images/reviewers/resin-logo.webp",
    avatar: "/images/reviewers/resin-product.webp",
    description:
      "When Stitch Labs shut down, we looked at several alternatives for inventory management. I went with a different provider for six weeks until I couldn't take the quirky interface and the bizarre errors anymore. With only 30 days left to find a solution, I contacted SKULabs, who pulled through in the clutch.",
  },
];

export default reviewsData;
