import paths from "helpers/paths";

//Icons
import inventorymanagement from "assets/icons/inventory-management.svg";
import delivery from "assets/icons/delivery.svg";
import purchasingreceiving from "assets/icons/purchasing-receiving.svg";
import tracking3PL from "assets/icons/tracking-3PL-icon.svg";
import bathPicking from "assets/icons/batch-picking.svg";
import catalogManagment from "assets/icons/catalog-managment.svg";
import insightsReporting from "assets/icons/insights-reporting.svg";
import orderPicking from "assets/icons/order-picking.svg";
import whManagment from "assets/icons/wh-managment.svg";
import barcode from "assets/icons/barcode.svg";

export interface FeatureContent {
  title: string;
  description: string;
  image?: string;
  icon?: string;
  alt?: string;
  link?: string;
  linkTitle?: string;
}

interface FeaturePage {
  title: string;
  description: string;
  video?: string;
  content: FeatureContent[];
}

export const featureData: Record<string, FeaturePage> = {
  [paths.features]: {
    title: "Manage Inventory with SKULabs",
    description: "Learn how SKULabs can help you run your ecommerce business",
    video: "",
    content: [
      {
        title: "Inventory Management",
        description:
          "Get up-to-date inventory counts for all of your items and synchronize inventory to all of your stores. Search or scan to get the count for a SKU, set low stock alerts, and see how many units have been sold.",
        link: paths.inventoryManagement,
        image: "/images/features/multi-shipping.webp",
        icon: inventorymanagement,
        alt: "Inventory Management",
      },
      {
        title: "Purchase Orders",
        description:
          "Send POs to any of your suppliers. Scan to receive against your POs verifying contents while incrementing stock. Simply generate multi-supplier POs based on open orders.",
        link: paths.purchaseOrder,
        image: "/images/features/purchase-orders.webp",
        icon: purchasingreceiving,
        alt: "Purchase Orders",
      },
      {
        title: "Warehouse Management",
        description:
          "Break a single warehouse into multiple stock-keeping locations or track inventory across multiple warehouses. Receive and return stock for any warehouse, and transfer inventory between stock locations.",
        link: paths.warehouseManagement,
        image: "/images/features/warehouse-management.webp",
        icon: whManagment,
        alt: "Warehouse Management",
      },
      {
        title: "Order Picking",
        description:
          "Save thousands of dollars per year by eliminating picking and shipping errors, and reduce the time spent on order returns and refunds. Pick using paper lists or from any mobile device or desktop computer.",
        link: paths.orderPicking,
        image: "",
        icon: orderPicking,
        alt: "Order Picking",
      },
      {
        title: "Multi-Carrier Shipping",
        description:
          "Process shipments and print labels from any shipping carrier without switching between shipping software. Use memorized shipment settings and automation rules to ship faster than ever.",
        link: paths.shipping,
        image: "/images/features/multi-shipping.webp",
        icon: delivery,
        alt: "Multi-Carrier Shipping",
      },
      {
        title: "Batch Picking",
        description:
          "Get your orders out the door faster by fulfilling all them at once. When you're done picking the orders in a batch, you can process their shipments and print labels in seconds.",
        link: paths.batchPicking,
        image: "",
        icon: bathPicking,
        alt: "Batch Picking",
      },
      {
        title: "Catalog Management",
        description:
          "SKULabs allows you to manage product catalogs across all sales channels, ensuring that your listings are always up-to-date with accurate stock information.",
        link: paths.catalogManagement,
        image: "",
        icon: catalogManagment,
        alt: "Catalog Management",
      },
      {
        title: "Order Management",
        description:
          "Get a bird's-eye view of what needs to be shipped with our simple item summaries. Create custom internal tags to keep track of your orders, and take phone orders and offline orders quickly and easily.",
        link: paths.orderManagement,
        image: "",
        icon: tracking3PL,
        alt: "Order Management",
      },
      {
        title: "Point of Sale",
        description:
          "Manage in-store and online sales seamlessly with SKULabs' Point of Sale (POS) integration. Sync your inventory across all channels, process transactions efficiently.",
        link: paths.pointOfSale,
        image: "",
        icon: barcode,
        alt: "Point of Sale",
      },
      {
        title: "Insights Reporting",
        description:
          "See picking and shipping speeds for each user. Analyze postage costs and per-item sales figures. Break down metrics by store, user, item, and more.",
        link: paths.insightsReporting,
        image: "",
        icon: insightsReporting,
        alt: "Insights Reporting",
      },
    ],
  },
  [paths.inventoryManagement]: {
    title: "Inventory Management with SKULabs",
    description: "Master your inventory with SKULabs Inventory features",
    video: "https://skulabs.wistia.com/medias/oiq1lc660y",
    content: [
      {
        title: "See stock levels for all of your items at a glance",
        description:
          "Take the guesswork and busywork out of tracking your inventory. Instead, get an up-to-date count for all your items across all your sales channels in one place. Search or scan to get the count for a SKU, set low stock alerts, and see how many units have been sold.",
        image: "",
        alt: "Manage products",
      },
      {
        title: "Track what's coming in and going out in real time",
        description:
          "As you submit purchase orders and ship orders, your inventory fluctuates rapidly. That's why we show you what's going out and what's going in for each of your SKUs. Use this quick view of your item's inventory flow to plan ahead for future purchasing and sales.",
        image: "",
        alt: "Manage products",
      },
      {
        title: "Synchronize inventory to all of your sales channels",
        description:
          "Keep all of your listings' inventory counts up to date without lifting a finger. By acting as the master of inventory, SKULabs can push the available inventory of an item to all of the listings that the item is linked to automatically.",
        image: "",
        alt: "Manage products",
      },
      {
        title: "Manage inventory across multiple warehouses and locations",
        description:
          "No matter where your inventory is, you can track and sync quantity changes as they happen. See how a SKU's stock breaks down between your storage facilities, distribution centers, and Amazon's FBA service.",
        image: "",
        alt: "Manage products",
      },
      {
        title: "Update stock with cycle counting, returns, and transfers",
        description:
          "Daunted by the task of counting all your inventory? Create cycle counts to count inventory for one part of your warehouse at a time. Use barcode scanning to make your returns and location transfers more accurate.",
        image: "",
        alt: "Manage products",
      },
    ],
  },
  [paths.purchaseOrder]: {
    title: "Purchase Orders",
    description: "Automated quantity suggestions and multi-supplier routing",
    video: "https://skulabs.wistia.com/medias/wbng7ksif9",
    content: [
      {
        title: "Send POs to any of your suppliers",
        description:
          "Purchasing inventory shouldn't be a full-time job for store owners. No matter how many sales channels you sell through, or how many suppliers you purchase from, we make creating and sending out purchase orders a snap.",
        image: "",
        alt: "Create purchase orders",
      },
      {
        title: "Scan PO barcodes to quickly start processing",
        description:
          "Simply scan the barcode in the corner of the purchase order to open it on SKULabs. From there, you can start scan receiving or modify the PO. Auto-close POs without scanning when scan verification isn't required.",
        image: "",
        alt: "Scan purchase orders",
      },
      {
        title:
          "Generate POs in bulk with the items needed for open orders or use logical rules like 30 days of sales + lead time.",
        description:
          "When orders are placed for items you don't keep in stock, you can quickly create dropship or backorder POs. SKULabs will automatically add the right quantities to the right POs with the right distributors. When maintaining stock, use our real-time rules engine to resupply your warehouse based on sales velocity and lead time. Easily route POs direct to each warehouse / retail store or use our transfer order replenishment reports instead.",
        image: "",
        alt: "Generate POs",
      },
    ],
  },
  [paths.orderPicking]: {
    title: "SKULabs - Order Picking",
    description: "Pick, pack, and ship your orders at top speed",
    video: "https://skulabs.wistia.com/medias/grpqkht7ad",
    content: [
      {
        title: "Barcode-based order verification",
        description:
          "Fulfill your orders with 100% accuracy by scanning order items before you ship them. Save thousands of dollars per year by eliminating picking and shipping errors, and reduce the time spent on order returns and refunds.",
        image: "",
        alt: "Manage products",
      },
      {
        title: "Scan packing slips to start picking",
        description:
          "Every packing slip that is printed through SKULabs has a barcode in the corner. Scanning this barcode will quickly open the order and allow you to pick and ship it. Use these barcoded packing slips to easily divide the fulfillment workload among your team members.",
        image: "",
        alt: "Manage products",
      },
      {
        title: "Print pick lists for batches of orders",
        description:
          "Anyone can start picking items for fulfillment with our simple paper pick lists. Select the orders you want to have picked, print the pick list, and hand it off to a team member. Pick lists will itemize each item, the quantity needed, and their location in your warehouse.",
        image: "",
        alt: "Manage products",
      },
      {
        title: "Pick individual orders or sorted batches",
        description:
          "Pick orders one at a time for accuracy and simplicity, or pick several orders in batches for maximum speed and efficiency. Create batches by selecting multiple orders by hand, or let us automatically create a batch with similar orders.",
        image: "",
        alt: "Manage products",
      },
    ],
  },
  [paths.shipping]: {
    title: "Multi-Carrier Shipping",
    description: "Rate shop and automate packaging selection with ease",
    video: "https://skulabs.wistia.com/medias/a4oa1zwln3",
    content: [
      {
        title: "Print labels from all your carriers",
        description:
          "Shop between multiple carriers rates and print shipping labels from every shipment provider you use without switching between shipping software. We integrate with USPS, UPS, FedEx, Canada Post, Royal Mail, Amazon Shipping, and dozens of other carriers from around the world.",
        image: "/images/features/multi-carrier-shipping/print-labels.webp",
        alt: "Manage products",
      },
      {
        title: "Validate addresses before shipping",
        description:
          "Check if an address is typed in correctly, or if it's a business or residence, with the push of a button. Our address validation system sends the shipment's address to the same service that your shipping carrier uses to determine if a submitted address is correct.",
        image:
          "/images/features/multi-carrier-shipping/validate-addresses.webp",
        alt: "Manage products",
      },
      {
        title: "Memorized shipment settings and powerful shipping rules",
        description:
          "Spend less time at the scale with our unique shipment memory system. Have your best staff use SKULabs first, trail blazing a set of memorized shipping defaults for your most common package configurations. Use shipping rules and default to automate method, packaging, insurance and more based on number of items, order weight, item tags, order tags, and anything else you can imagine.",
        image: "/images/features/multi-carrier-shipping/control-sync.webp",
        alt: "Manage products",
      },
      {
        title: "Process batches of shipments",
        description:
          "Print hundreds of shipping labels in seconds. Just select your orders, check the shipment settings for each of them, and click to submit the shipments and start printing. There's no need to split up shipping jobs by carrier or sales channel.",
        image: "/images/features/multi-carrier-shipping/control-sync.webp",
        alt: "Manage products",
      },
    ],
  },
  [paths.batchPicking]: {
    title: "Batch Picking",
    description:
      "Automatically group orders for efficient pick, pack, and ship.",
    video: "https://skulabs.wistia.com/medias/ahthhtoeu2",
    content: [
      {
        title: "Pick, pack, and ship multiple orders",
        description:
          "Get your orders out the door faster by fulfilling all them at once. If several orders include a SKU, you can pick that SKU once for all them, saving you from a return trip to that part of your warehouse.",
        image: "",
        alt: "Manage products",
      },
      {
        title: "See stock levels for all of your items at a glance",
        description:
          "Take the guesswork and busywork out of tracking your inventory. Instead, get an up-to-date count for all your items across all your sales channels in one place. Search or scan to get the count for a SKU, set low stock alerts, and see how many units have been sold.",
        image: "",
        alt: "Manage products",
      },
      {
        title: "Automatically sort batches of like orders",
        description:
          "Instead of selecting the individual orders you want to add to a batch, you can create a new batch with the push of a button. SKULabs automatically checks your open orders for similar items or for items that are close together, ensuring that the batch will be fast and easy to pick.",
        image: "",
        alt: "Manage products",
      },
      {
        title: "Guided batch picking ensures efficiency",
        description:
          "To make your order picking process as fluid and efficient as possible, the batch system will guide you from one SKU to its nearest neighbor on the picking list. This eliminates the need to walk back and forth between the same picking locations.",
        image: "",
        alt: "Manage products",
      },
      {
        title: "Get rates and print labels on the fly",
        description:
          "When you're done picking the orders in a batch, you can process their shipments and print labels in seconds. Order combinations that have been shipped before will have the previous shipment settings filled in. Just double-check that the settings are correct and print all the labels out at once.",
        image: "",
        alt: "Manage products",
      },
    ],
  },
  [paths.orderManagement]: {
    title: "Order Management",
    description: "Control all of your orders in one place",
    video: "https://skulabs.wistia.com/medias/o5ugmsbqv0",
    content: [
      {
        title: "Multichannel, unified order list",
        description:
          "With our 100% cloud-based order management system, there's no need to juggle invoice sheets or clipboards full of open orders. Just open the order you want to work with, search by customer or product, or sort through all your open orders from all your sales channels at once.",
        image: "",
        alt: "Manage products",
      },
      {
        title: "See item summaries for your open orders",
        description:
          "Get a bird's-eye view of what needs to be shipped with our simple item summaries. Select a group of orders and get an itemized list of what items need to be fulfilled for them. Use item summaries to create pick lists or to double-check your stock or purchasing needs for the day.",
        image: "",
        alt: "Manage products",
      },
      {
        title: "Organize your orders with tags and fulfillment statuses",
        description:
          'Create custom internal tags, like "Out of stock" or "Hold for tomorrow", to keep track of your orders. Color-code your tags to help tell them apart at a glance. See which step each order is going through in your fulfillment process with order status updates and history.',
        image: "",
        alt: "Manage products",
      },
      {
        title: "Create manual orders and process manual payments",
        description:
          "Take phone orders and offline orders quickly and easily. Skip the hoop-jumping to create an order in one of your sales channels; just fill in the customer information, add the items requested, and save the order. Once the order is saved, your team can pick and ship the order with ease.",
        image: "",
        alt: "Manage products",
      },
    ],
  },
  [paths.pointOfSale]: {
    title: "Point of Sale",
    description: "Seamless connection to your POS of choice.",
    video: "",
    content: [
      {
        title: "Scan item barcodes to quickly build orders",
        description:
          "You can take phone orders in SKULabs, easily scan items by barcode or serial number.",
        image: "",
        alt: "Manage products",
      },
      {
        title: "POS orders seamlessly import into SKULabs",
        description:
          "Your retail sales from Square POS, Shopify POS, or Shift4Shop POS seamlessly import into SKULabs. As products are sold, your inventory stays accurate in SKULabs.",
        alt: "Manage products",
      },
    ],
  },
  [paths.warehouseManagement]: {
    title: "Warehouse Management",
    description: "Track inventory across multiple locations",
    video: "https://skulabs.wistia.com/medias/7y813j57p6",
    content: [
      {
        title: "Manage all your stocking and shipping centers in one place",
        description:
          "Whether your inventory is kept across the hall or across the country, it's easy to track stock changes across your entire operation. Break a single warehouse into multiple stock-keeping locations or track inventory across multiple warehouses.",
        image: "",
        alt: "Manage products",
      },
      {
        title: "Receive and return stock for any warehouse",
        description:
          "Regardless of where the goods need to go, you can process the receipt from the same system. Simply scan the barcodes for the items you are receiving, select the location each item is going to, and the inventory counts will be adjusted accordingly.",
        alt: "Manage products",
      },
      {
        title: "Transfer inventory between stock locations",
        description:
          "If you put the same SKU away in multiple locations, you can track inventory for each of those bins separately. You can also create transfer orders to keep track of when stock moves between those bins, giving you complete control over your multi-level inventory.",
        alt: "Manage products",
      },
    ],
  },
  [paths.catalogManagement]: {
    title: "Catalog Management",
    description: "An inventory system for multi-channel sellers",
    video: "https://skulabs.wistia.com/medias/r2w0682t1m",
    content: [
      {
        title: "Automatic catalog import from your store",
        description:
          "Get up and running in no time by letting SKULabs build your catalog items for you. We can use data from your primary sales channel to create catalog items for each listing, complete with a random barcode if there isn't one available to us.",
        image: "",
        alt: "Manage products",
      },
      {
        title: "Track multiple SKUs for a single item",
        description:
          "We're multi-channel experts. Pick orders from multiple channels with one one barcode and sync inventory counts across all your channels with ease. Support for multiple barcodes too, no more issues ordering from multiple suppliers.",
        alt: "Manage products",
      },
      {
        title: "Bundle separate items into kits",
        description: `Effortlessly sync your catalog and orders from any sales channel using our "Remote Store" feature. With support for XML, CSV, and JSON imports, keep your SKULabs catalog up to date automatically, even with platforms we don't natively support.`,
        alt: "Manage products",
      },
      {
        title: "Catalog for supplies and materials for assembly",
        description:
          "Operate an independent catalog from your sales channel for your supplies and materials. Keep track of your warehouse supplies, like boxes, tape, and other consumables. Also track manufacturing supplies and raw materials for use with kits / Bill-of-Materials BOM.",
        alt: "Manage products",
      },
    ],
  },
  [paths.insightsReporting]: {
    title: "Insights Reporting",
    description: "Insights for your operations",
    video: "https://skulabs.wistia.com/medias/ug175ert0j",
    content: [
      {
        title: "See picking and shipping speeds for each user",
        description:
          "Is everyone in your shop keeping up the picking and shipping pace? With user insights, you can see each team member's average order speed and accuracy over time. Compare multiple users with each other to see where to make improvements.",
        image: "",
        alt: "Manage products",
      },
      {
        title: "Analyze postage costs and per-item sales figures",
        description:
          "Shipping insights allow you to look at how much your shipments cost per store, per carrier, and as a percentage of your sales revenue. Item insights show your top sellers by quantity or by revenue, and allow you to see which items take the longest to fulfill.",
        alt: "Manage products",
      },
      {
        title: "Break down metrics by store, user, item, and more",
        description: `Not only do we collect a huge amount of data for you to analyze, we give you the ability to funnel down to the numbers that matter most to you. View order fulfillment performance by store or by user, or compare stats for individual users or items.`,
        alt: "Manage products",
      },
    ],
  },
};

export const inventoryManagementContent: FeatureContent[] = [
  {
    title: "Inventory Management",
    description:
      "Get up-to-date inventory counts for all of your items and synchronize inventory to all of your stores. Search or scan to get the count for a SKU, set low stock alerts, and see how many units have been sold.",
    link: paths.inventoryManagement,
    image: "/images/features/multi-shipping.webp",
    icon: inventorymanagement,
    alt: "Inventory Management",
  },
];

export default featureData;
