import React, { useState } from "react";
import classNames from "classnames";
import { Plan } from "data/pricingData";
import SignupModal from "components/Layouts/modal/SignupModal";

type PlanProps = {
  plan: Plan;
  isHighlighted: boolean;
  getButtonText: (planName: string) => string;
  renderFeatureValue: (plan: Plan, index: number) => JSX.Element | string;
};

const PricingPlan: React.FC<PlanProps> = React.memo(
  ({ plan, isHighlighted, getButtonText, renderFeatureValue }) => {
    const [isPlanBodyVisible, setIsPlanBodyVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const toggleVisibility = () => setIsPlanBodyVisible((prev) => !prev);
    const openModal = () => setIsModalVisible(true);
    const closeModal = () => setIsModalVisible(false);

    return (
      <div className={classNames("plan", { highlight: isHighlighted })}>
        <div
          className={classNames("plan-info", {
            visible: isPlanBodyVisible,
            hidden: !isPlanBodyVisible,
          })}
        >
          <p className="plan-name">{plan.name}</p>
          <p className="plan-price">{plan.price}</p>
          <p className="plan-description">{plan.description}</p>
          <a
            href={`https://next.skulabs.com/get-started?plan=${plan.code}#activate`}
            className="cta"
          >
            {getButtonText(plan.name)}
          </a>
          {/* <button onClick={openModal} className="cta">
            {getButtonText(plan.name)}
          </button> */}
          <button onClick={toggleVisibility} className="cta cta-secondary">
            {isPlanBodyVisible ? "Hide Plan Details" : "Show Plan Details"}
          </button>
        </div>
        <div
          className={classNames("plan-body", {
            visible: isPlanBodyVisible,
            hidden: !isPlanBodyVisible,
          })}
        >
          {plan.features.map((feature, index) => (
            <p
              key={feature.name}
              data-rowname={feature.name}
              className={classNames("plan-value", {
                include: feature.included || feature.limit,
                uninclude: !feature.included && !feature.limit,
              })}
            >
              {renderFeatureValue(plan, index)}
            </p>
          ))}
        </div>

        <SignupModal
          plan={plan.name.toLowerCase()}
          code={plan.code}
          isVisible={isModalVisible}
          onClose={closeModal}
        />
      </div>
    );
  }
);

export default PricingPlan;
